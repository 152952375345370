import React from "react";
import { getServiceUri } from "../../auto/js/metadata";
import { ADDRESS_ORDER_BY_AREA_ID } from "../../auto/js/metadata/AddressOrderBy";
import { geoMetadataLoader } from "../../auto/js/metadata/GeoMetadataLoader";
import { rest, t } from "../../auto/js/services";

const gender = { 1: "MALE", 2: "FEMALE" };
const maritalStatus = {
  1: "SINGLE",
  2: "MARRIED",
  3: "DIVORCED",
  4: "WIDOWED",
};

export const loadPersonData = async (id) => {
  return await rest
    .read("vital-record", id)
    .then(async (response) => {
      let address = null;
      if (response.status) return undefined;
      else {
        if (response.maritalStatus)
          response.maritalStatus = maritalStatus[response.maritalStatus];
        if (response.gender) response.gender = gender[response.gender];
        response["address"] = address;
        if (response.faceId != null) {
          const token = await rest.getToken(
            getServiceUri() + "token/get-auth-code"
          );
          const imageUrl =
            getServiceUri() + "face/image/" + response.faceId + "/" + token;

          response["image"] = {
            x: 0.5,
            y: 0.5,
            scale: 1,
            rotate: 0,
            url: imageUrl != null ? imageUrl : "/public/avatar.png",
            isEmpty: true,
          };

          return response;
        } else {
          response["image"] = {
            x: 0.5,
            y: 0.5,
            scale: 1,
            rotate: 0,
            url: "/public/avatar.png",
            isEmpty: true,
          };
          return response;
        }
      }
    })
    .then((form) => {
      if (!form) return undefined;
      else
        return buildAddressData({}, form.id).then((address) => {
          form["address"] = address;
          return form;
        });
    });
};

export const filterPersonData = (data) => {
  let faceUrl = null;
  if (data.faceId != null) {
    faceUrl = getServiceUri() + "face/image/" + data.faceId;
  }
  data["image"] = {
    x: 0.5,
    y: 0.5,
    scale: 1,
    rotate: 0,
    url: faceUrl != null ? faceUrl : "/public/avatar.png",
    isEmpty: true,
  };
  if (data.maritalStatus)
    data.maritalStatus = maritalStatus[data.maritalStatus];
  if (data.gender) data.gender = gender[data.gender];
  return data;
};

const buildAddressData = async (query, id) => {
  let filter = query;
  filter["address"] = { vitalRecordId: id };
  filter["orderBy"] = ADDRESS_ORDER_BY_AREA_ID;
  filter.orderDirection = null;
  filter.offset = query.page * query.pageSize;
  if (query.search && query.search != "") {
    pojoMetadata["address"].columns.forEach((element) => {
      if (element.type == "text") {
        filter["address"][element.key] = query.search;
      }
    });
    filter["and"] = false;
  }
  return await getData(filter).then((response) => {
    if (response.length) {
      let areaId = response[0].areaId;
      return geoMetadataLoader.locationsdata.find((x) => x.areaId == areaId);
    } else return "";
  });
};

const getData = async (filter) => {
  return await rest.search("address", filter);
};

export const Section = ({ name }) => {
  return (
    <>
      <div class="col-md-12 sectiontitle">
        <h2 style={{ display: "inline" }}>{t(name)}</h2>
      </div>
    </>
  );
};

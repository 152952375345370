import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME } from "../../../../auto/js/metadata/CivilStatusMtlbOrderBy";
import { MTLB_TYPE_DEATH_REGISTRATION } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services";


export const deathRegistrationListColumns = [
	{title: "id", field: "vitalRecordId"},
    {title: "firstname", field: "firstname"},
    {title: "secondname", field: "secondname"},
    {title: "thirdname", field: "thirdname"},
    {title: "fourthname", field: "fourthname"},
    {title: "dateOfDeath", field: "dateOfDeath"},
];

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "civil-status-mtlb/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "civil-status-mtlb/count-document/", "POST", filter);
}

export const getOrderBy = () => {
    return CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_DEATH_REGISTRATION]
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let face = null;
        if (row.face != null) {
			let mimeType = row['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", row.face);		
		}
        row.image = (face != null)?face:"/public/avatar.png";
        let date = row.dateOfDeath
        if ( date !== null)
        row.dateOfDeath = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
	list.push("E19");
    return list;
}
import * as React from 'react';
import QRCode from "react-qr-code";
import {t} from "../services";
import {birthCertificateContent} from "../../../main/js/forms/CivilRecordFormCommon";

const formatDate = (date) => {
	let d = new Date(date[0], date[1]-1, date[2]);
	return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}

export class BirthCertificate extends React.Component {
	constructor(props) {
		super(props);
		this.data = props.data;
	}

	// @ts-ignore
	render() {
		let birthdate = this.data.birthdate? formatDate(this.data.birthdate):'Unknown';
		let motherBirthdate = this.data.motherBirthdate? formatDate(this.data.motherBirthdate):'Unknown';
		let fatherBirthdate = this.data.fatherBirthdate? formatDate(this.data.fatherBirthdate):'Unknown';
		var currentdate = new Date();
		var datetime = currentdate.getDate() + "/"
			+ (currentdate.getMonth() + 1) + "/"
			+ currentdate.getFullYear() + " @ "
			+ currentdate.getHours() + ":"
			+ currentdate.getMinutes() + ":"
			+ currentdate.getSeconds();
		let fullname = '';
		fullname += this.data.firstname ? this.data.firstname + ' ' : '';
		fullname += this.data.secondname ? this.data.secondname + ' ' : '';
		fullname += this.data.fourthname ? this.data.fourthname + ' ' : '';
		let childGender = this.data.gender = 1?'MALE':'FEMALE';

		return (

			<div className="page">
				<div className="header">
					<div className="headercol1">
						<h2 className="lefttext"> {birthCertificateContent.leftTitle1}<br/>
							{birthCertificateContent.leftTitle2}</h2></div>
					<div className="headercol2 logos">

					</div>
					<div className="headercol3">
						<h2 className="righttext">{birthCertificateContent.rightTitle1}<br/>{birthCertificateContent.rightTitle2}</h2>
					</div>
					<div className="headercol4">

					</div>
					<div className="headercol5">
						<h1>{birthCertificateContent.mainTitle}</h1>
						<h2>{birthCertificateContent.mainSubTitle}</h2>
					</div>
					<div className="headercol6">
						<h2 className="righttext idnumber1">{t`National ID Number`}: {this.data.id}</h2>
					</div>
				</div>
				<div className="bodycontent">
					<div className="bodycol1">
						<h1 className="lefttext">{t`Child`}</h1>
						<div className="nestedcol1">
							<p>{t`Surname`}<br/>
								{t`First Name(s)`}<br/>
								{t`Melanesian Name`}<br/>
								{t`Sex`}<br/>
								{t`National ID Number`}<br/>
							</p>
							<p>
								{t`Date of birth`} <br/>
								{t`Time of birth`} <br/>
								{t`Birth Location`}<br/>
								{t`Usual Residence`}<br/>
							</p>
							<p>
								{t`Parents Married`}?
							</p>
						</div>
						<div className="nestedcol2">
							<p> {this.data.lastname}<br/>
								{this.data.firstname}<br/>
								{this.data.thirdname}<br/>
								{childGender}<br/>
								{this.data.id}<br/>
							</p>
							<p>
								{birthdate}<br/>
								{this.data.birthtime}<br/>
								{this.data.birthPlace}<br/>
								{this.data.birthCountry}<br/>
							</p>
							<p>
							</p>
						</div>
					</div>

					<div className="bodycol2">
						<h1 className="lefttext">{t`Mother`}</h1>
						<div className="nestedcol1">
							<p>{t`Surname`}<br/>
								{t`First Name(s)`}<br/>
								{t`Melanesian Name`}<br/>
								{t`National ID Number`}<br/>
							</p>
							<p>
								{t`Date of birth`} <br/>
								{t`Birth Location`}<br/>
								{t`Citizenship`} <br/>
								{t`Parents of mother`}<br/>
							</p>
							<p>
								{t`Occupation`}
							</p>
						</div>
						<div className="nestedcol2">
							<p>{this.data.motherSecondname}<br/>
								{this.data.motherFirstname}<br/>
								{this.data.motherThirdname}<br/>
								{this.data.motherId}<br/>
							</p>
							<p>
								{motherBirthdate}<br/>
								{this.data.motherBirthPlace}<br/>
								<br/>
								{this.data.grandFatherMotherFirstname} ,{this.data.grandMotherMotherFirstname}<br/>
								<br/>
							</p>
							<p>

							</p>
						</div>
					</div>
					<div className="bodycol3">
						<h1 className="lefttext">{t`Father`}</h1>
						<div className="nestedcol1">
							<p>{t`Surname`}<br/>
								{t`First Name(s)`}<br/>
								{t`Melanesian Name`}<br/>
								{t`National ID Number`}<br/>
							</p>
							<p>
								{t`Date of birth`} <br/>
								{t`Birth Location`}<br/>
								{t`Citizenship`} <br/>
								{t`Parents of father`}<br/>
							</p>
							<p>
								{t`Occupation`}
							</p>
						</div>
						<div className="nestedcol2">
							<p>{this.data.fatherSecondname}<br/>
								{this.data.fatherFirstname}<br/>
								{this.data.fatherThirdname}<br/>
								{this.data.fatherId}<br/>
							</p>
							<p>
								{fatherBirthdate}<br/>
								{this.data.fatherBirthPlace}<br/>
								<br/>
								{this.data.grandFatherFatherFirstname} ,{this.data.grandMotherFatherFirstname}<br/>
								<br/>
							</p>
							<p>

							</p>
						</div>
					</div>
					<div className="bodycol4">
						<h1 className="lefttext">{t`Witnesses`}</h1>
						<div className="nestedcol1">
							<h3>{t`Witness 1`}</h3>
							<p className="bottom">{t`Full Name`}<br/>
								{t`National ID Number`}
							</p>
							<h3>{t`Witness 2`}</h3>
							<p className="bottom">{t`Full Name`}<br/>
								{t`National ID Number`}
							</p>
						</div>
						<div className="nestedcol2">
							<h3>&nbsp;</h3>
							<p className="bottom">{this.data.witness1Firstname}<br/>
							</p>
							<h3>&nbsp;</h3>
							<p className="bottom">{this.data.witness2Firstname}<br/>
							</p>
						</div>
					</div>
					<div className="bodycol5">
						<h1 className="lefttext">{t`Declaration`}</h1>
						<div className="nestedcol1">
							<p>{t`Full Name`}<br/>
								{t`National ID Number`}<br/>
								{t`Date`}<br/>
								{t`Place`}<br/>
							</p>
							<p>
								{t`Officer`}<br/>
								{t`Position`}<br/>
							</p>
						</div>
						<div className="nestedcol2">
							<p> <br/>
								<br/>
								<br/>
								<br/>
							</p>
							<p>
								<br/>
								<br/>
							</p>
						</div>
					</div>
					<div className="bodycol6">
						<div className="official">
							<div className="qr-code">
								<QRCode value={fullname + "\nCertificate issued on " + datetime + ' - Signed by Director Andy Calo'} size={80}/>
							</div>
							<div className="stamp"></div>
							<div className="signature"></div>
						</div>
						<p className="lefttext idnumber1">Certified to be a true copy issued without alteration
							at </p><p className="strongstyle"><strong>{birthCertificateContent.signedBy}<br/>Registrar General</strong></p>

					</div>

				</div>
			</div>

		)
	}
}
import React from "react";

import '../pages/printable.css';
import { AddressComponent } from "../../../auto/js/widgets/AddressComponent";
import { Section, loadPersonData } from "../utils";
import { PersonComponent } from "../../../auto/js/widgets/PersonComponent";

const gender = { 1: "MALE", 2: "FEMALE" };
const maritalStatus = { 1: "SINGLE", 2: "MARRIED", 3: "DIVORCED", 4: "WIDOWED" };

export const civilRecordFormfields = [

     { name: "image", type: "image", x: 1, y: 2, layout: "col-md-12" },
    { name: "tags", type: "tags", x: 1, y: 1, layout: "col-md-12" },
    { name: "id", type: "number", x: 1, y: 3, layout: "col-md-12" },
    { name: "firstname", type: "text", x: 1, y: 4, layout: "col-md-6"},
     { name: "secondname", type: "text", x: 2, y: 4, layout: "col-md-6" },
    { name: "thirdname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    { name: "fourthname", type: "text", x: 2, y: 5, layout: "col-md-6"},
    {name: "fifthname", type: "text", x:1, y: 6, layout:"col-md-6"},
    { name: "gender", type: "select", x: 1, y: 7, layout: "col-md-6", metadata: () => gender },
	{name: "birthPlace", type: "custom", x:1, y:9, layout:"col-md-12", component: (name, disabled) => <AddressComponent name={name} disabled={disabled} linear={true}/>},
    {name: "fatherDetails", type: "custom", x:1, y:14, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "fatherId", type: "custom", x:1, y:15, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "reportedFatherName", type: "text", x:1, y:18, layout:"col-md-12"},
	{name: "motherDetails", type: "custom", x:1, y:19, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "motherId", type: "custom", x:1, y:20, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "reportedMotherName", type: "text", x:1, y:21, layout:"col-md-12"},
	{name: "", type: "custom", x:1, y:22, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "reportedPaternalGrandfatherName", type: "text", x:1, y:23, layout:"col-md-12"},
	{name: "reportedPaternalGrandmotherName", type: "text", x:1, y:24, layout:"col-md-12"},
	{name: "reportedMaternalGrandfatherName", type: "text", x:1, y:25, layout:"col-md-12"},
	{name: "reportedMaternalGrandmotherName", type: "text", x:1, y:26, layout:"col-md-12"},
    { name: "notes", type: "text", x: 1, y: 27, layout: "col-md-12" }
]; 

export const birthCertificateContent =

    {
        leftTitle1: "Republic of Guinea-Bissau",
        leftTitle2: "Civil Registration and Identity Management",
        rightTitle1: "Republic of Guinea-Bissau",
        rightTitle2: "Civil Registration and Identity Management",
        mainTitle: "Guinea-Bissau Birth Registration Certificate",
        mainSubTitle: "",
        signedBy: ""
    };
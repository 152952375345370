import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { AlertDialog } from "../../widgets";
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import {  updatePendingBirthRegistrationForm, rejectBirthRegistrationForm, loadBirthRegistrationFormData } from "./BirthRegistrationApi";
import { birthRegistrationFields } from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../../widgets/FileUploader";

import { createTableComponent } from "../../widgets/TableComponent";
import { loadCivilStatusMtlbAttachment } from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";

let BirthRegistrationForm = createFormComponent(birthRegistrationFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<BirthRegistrationForm ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} onSubmit={update} id={this.props.id} buttons={() => getButtons(this.props.id)} />
            <FileUploader onDropCallback={(file) => uploadCivilStatusMtlbAttachment(file, this.props.id)} loadData={async () => loadCivilStatusMtlbAttachment(this.props.id)} handleDelete={(id) => handleCivilStatusMtlbAttachmentDelete(id)} handleClick={(id) => handleCivilStatusMtlbAttachmentClick(id)}/>
		</>
		)
	}

}

const uploadCivilStatusMtlbAttachment = (file, id) => {
	rest.upload('civil-status-mtlb-attachment', id, file).then((response) => FILE_UPLOADED_EVENT.publish({ id: response.id, fileName: file.name }))
}
const handleCivilStatusMtlbAttachmentDelete = (id) => {
	rest.delete('civil-status-mtlb-attachment', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const handleCivilStatusMtlbAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'civil-status-mtlb-attachment' + '/' + id + '/' + token;
};

export const displayReadPendingBirthRegistrationForm = (id) => {
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <FormComponent key={uuid} id={id}/>
	});
}

const update = async (formData) => {
	return updatePendingBirthRegistrationForm(formData);
}

const reject = async (id) => {
    return rejectBirthRegistrationForm(id);
}

const loadFormData = async (id) => {
	return await loadBirthRegistrationFormData(id);
}

const getButtons = (id) => {
	return (
    <>
	<div className="row">
		<div className="col-md-6">
        	<button style={{ minWidth: '5rem' }} type="submit"> {t`Update`} </button>
		</div>
		<div className="col-md-6">
        	<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={() => reject(id)}> {t`Reject`} </button>
		</div>
	</div>
    </>
    )
}

import React, { useEffect, useState } from 'react';
import { createTableComponent } from '../../../auto/js/widgets/TableComponent';
import {v4 as uuidv4} from 'uuid';
import { OPEN_VIEW_EVENT } from '../../../auto/js/events/Gui';
import { rest } from '../../../auto/js/services';
import { getServiceUri } from '../../../auto/js/metadata';


const fields = [
  {title: "username", field: "username"},
  {title: "score", field: "score"},
  {title: "quality", field: "quality"}
];

export class StatisticsTableView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []

    }
    rest.request(getServiceUri()+'statistics/get-user-digitalization-statistics','GET',null).then(response => {
      this.setState({data: response});
    });
  };
  
  render () {
    const UserStatisticsList = createTableComponent(fields);
    return (
      <>
        <UserStatisticsList key={this.props.key} loadData={this.state.data} localData={true}/>
      </>
    );
  }
};

export function displayStatisticsTable() {
  let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <StatisticsTableView key={uuid} />
	});
}
import React, { Component } from 'react';
import { getServiceUri } from '../metadata';

class PictureComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      token: null,
    };
  }

  componentDidMount() {
    this.loadImage();
  }

  async loadImage() {
    try {
      const { id, token } = this.props;
      console.log('Image loaded:', id, token);
      this.setState({ id, token });
    } catch (error) {
      console.error('Error loading image:', error);
    }
  }

  render() {
    // const { imageURL } = this.state;
    return (
      <div>
        {getServiceUri() && <img src={getServiceUri() + 'civil-status-mtlb-attachment/content/' + this.state.id+ '/' + this.state.token} alt="Image"   style={{marginLeft: "auto", marginRight: "auto", display:"block"}} width="500" height="700"/>}
      </div>
    );
  }
}

export default PictureComponent;

import React, {useEffect} from "react";
import {v4 as uuidv4} from 'uuid';
import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import {officeMetadataLoader} from "../../metadata/OfficeMetadataLoader";
import {Observable} from "../../../../auto/js/events/Observable";

const conservatoireObservable = new Observable();

const handleConservatoireChange = (value) => {
    conservatoireObservable.publish(officeMetadataLoader.getChilds(value.key))
}

const fields = [
    {
    name:"conservatoire",
    type:"autocomplete",
    x:1, y:0, layout:"col-md-6",
        metadata: () => officeMetadataLoader.getRootNodes(),
        handleChange:(data) => handleConservatoireChange(data),   
    },
    {
    name:"office",
    type:"text",
    x:1, y:5, layout:"col-md-6",
    },
];

let OfficeForm = createFormComponent(fields);

class FormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        CAN_I_SWITCH.pickUpThePhone(this.listen);
        this.state = {
        closeRequested: undefined
        }
    }

    componentDidMount() {
    this.props.loadData().then(
    rowdata => {
        conservatoireObservable.publish(officeMetadataLoader.getChilds(rowdata?.conservatoire?.key));

    }
    )
    }

    listen = (closeMe) => {
        if (!this.isDirty())
            closeMe(true);
        this.setState({closeRequested: closeMe})
    }

    isDirty = () => {
        return this.myRef.current.isDirty();
    }

    handleDialogCancel = () => {
        this.state.closeRequested(false);
        this.setState({closeRequested: undefined});
    }

    handleSave = () => {
        this.myRef.current.save().then(() => {
        this.state.closeRequested(true);
    });
    }

    handleDontSave = () => {
        this.state.closeRequested(true);
    }

    render() {
        return (
        <>
        <AlertDialog
                title={t`Save your changes ?`}
                open={(this.state.closeRequested && this.isDirty())?true:false}
                handleClose={this.handleDialogCancel}
                noAgree={true}
                save={this.handleSave}
                dontSave={this.handleDontSave}
        />
        <OfficeForm ref={this.myRef} key={this.props.key} loadData={() => this.props.loadData()} onSubmit={this.props.onSubmit} id={this.props.id} buttons={getButtons} />
        </>
        )
    }

}

export const displayNewOfficeForm = () => {
    let uuid = uuidv4();
    OPEN_VIEW_EVENT.publish({
        uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(fields)} onSubmit={save}/>
    });
}

export const displayReadOfficeForm = (rowdata) => {
    let uuid = uuidv4();
    OPEN_VIEW_EVENT.publish({
        uuid, view: () => <FormComponent key={uuid} loadData={() => Promise.resolve(rowdata)} onSubmit={update}
        id={rowdata.officeId}/>
    });
}

const save = async (formData) => {
    let dto = form2dto(formData);
    dto.id = null;
    dto.areaId = officeMetadataLoader.incrementOfficeAreaIdFromParentId(dto.parentId);
    try {
        return rest.create('office', dto).then((response) =>{
        if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error")
        else {
            showNotification(t`Created Office`, "success");
            officeMetadataLoader.load();
        }
        });
    } catch (err) {
    alert(err);
    }
}

const update = async (formData) => {
    let dto = form2dto(formData);
    try {
        return rest.update('office', dto).then((response) =>{
            if (response.status)
                showNotification(response.message.split('Detail: ')[1], "error")
            else {
                showNotification(t`Updated Office`, "success");
                officeMetadataLoader.load();
            }
        });
    } catch (err) {
    alert(err);
    }
}


const form2dto = (data) => {
    let updatedata = {};
    updatedata.id = ~~(data.id);
    updatedata.areaId = data.areaId;
    updatedata.name = data.office;
    updatedata.type = 2;
    updatedata.parentId = ~~(data.conservatoire?.key);
    if(updatedata.areaId == "")
    updatedata.areaId = null;
    if(updatedata.name == "")
    updatedata.name = null;
    updatedata.disabled = false;
    return updatedata
}

const buildEmptyObject = (fields) => {
    const empty = {};
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        switch (field.type) {
        case ("text"):
        empty[field.name] = "";
        break;
        case ("number"):
        empty[field.name] = "";
        break;
        case ("checkbox"):
        empty[field.name] = false;
        break;
        case ("timestampz"):
        empty[field.name] = '';
        break;
        case ("date"):
        empty[field.name] = null;
        break;
        case ("select"): // dynamic lists, loaded from the backend
        empty[field.name] = '';
        break;
        case ("list"): // static lists, hardcoded
        empty[field.name] = '';
        break;
        case ("password"):
        empty[field.name] = '';
        break;
        }
    }
    return empty;
}

const getButtons = () => {
    return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}
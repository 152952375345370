import React from "react";
import * as Yup from 'yup';

import { PersonComponent } from "../../../../auto/js/widgets/PersonComponent";
import { Section, loadPersonData } from "../../utils";
import { t } from '../../../../auto/js/services/i18ndb';
import { OfficeComponent } from "../../widgets/OfficeComponent";
import { AddressComponent } from "../../../../auto/js/widgets/AddressComponent";

const gender = {1:"MALE", 2:"FEMALE"};

export const digitalizationFields = [
	{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "image", type:"image", x:1, y:2, layout:"col-md-12"},
	{name: "office", type: "custom", x:1, y:3, layout: "col-md-12", component: (name, disabled) => <OfficeComponent name={name} disabled={disabled} linear={true}/>},
	{name: "year", type: "text", x:1, y:3, layout: "col-md-12"},
	{name: "book", type: "text", x:1, y:4, layout: "col-md-12"},
	{name: "page", type: "text", x:1, y:5, layout: "col-md-12"},
	{name: "birthdate", type: "date", x:1, y: 6, layout:"col-md-6",
		"validation": Yup.date().nullable().default(undefined).required('A date of birth is required')
	},
	{name: "birthTime", type: "time", x:2, y:6, layout:"col-md-6"},
	{name: "birthPlace", type: "custom", x:1, y:8, layout:"col-md-12", component: (name, disabled) => <AddressComponent name={name} disabled={disabled} linear={true}/>},
	{name: "childDetails", type: "custom", x:1, y:9, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "gender", type: "select", x:1, y: 10, layout:"col-md-6", metadata: () => gender,
		"validation": Yup.string().nullable().default(undefined).required(t`Gender is required`)},
    {name: "firstname", type: "text", x:1, y:11, layout:"col-md-6", 
		"validation": Yup.string().nullable().default(undefined).min(1, t`First name must be at least one character long`).max(40, t`First name must be less than fourty characters long`).required(t`First name is required`)},
    {name: "secondname", type: "text", x:2, y:11, layout:"col-md-6", 
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Second name must be at least one character long`).max(40, t`Second name must be less than fourty characters long`)},
    {name: "thirdname", type: "text", x:1, y: 12, layout:"col-md-6", 
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Family name must be at least one character long`).max(40, t`Family name must be less than fourty characters long`).required(t`Family name is required`)},
    {name: "fourthname", type: "text", x:2, y: 12, layout:"col-md-6",
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Third name must be at least one character long`).max(40, t`Third name must be less than fourty characters long`)},
	{name: "fifthname", type: "text", x:1, y: 13, layout:"col-md-6",
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Marriage name must be at least one character long`).max(40, t`Marriage name must be less than fourty characters long`)},
	{name: "sixthname", type: "text", x:2, y: 13, layout:"col-md-6",
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Marriage name must be at least one character long`).max(40, t`Marriage name must be less than fourty characters long`)},
	{name: "fatherDetails", type: "custom", x:1, y:14, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "fatherId", type: "custom", x:1, y:15, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "reportedFatherName", type: "text", x:1, y:18, layout:"col-md-12"},
	{name: "motherDetails", type: "custom", x:1, y:19, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "motherId", type: "custom", x:1, y:20, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "reportedMotherName", type: "text", x:1, y:21, layout:"col-md-12"},
	{name: "", type: "custom", x:1, y:19, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "reportedPaternalGrandfatherName", type: "text", x:1, y:22, layout:"col-md-12"},
	{name: "reportedPaternalGrandmotherName", type: "text", x:1, y:23, layout:"col-md-12"},
	{name: "reportedMaternalGrandfatherName", type: "text", x:1, y:24, layout:"col-md-12"},
	{name: "reportedMaternalGrandmotherName", type: "text", x:1, y:25, layout:"col-md-12"},
];
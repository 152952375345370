import React from "react";
import {v4 as uuidv4} from 'uuid';
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT } from '../../../auto/js/events/Gui';
import { rest, t } from "../../../auto/js/services";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { showNotification } from "../../../auto/js/utils";

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};
	
const fields = [
    {name: "partner1VitalRecordId", type: "text", x:1, y:2, layout:"col-md-12"},
    {name: "partner1Firstname", type: "text", x:1, y:3, layout:"col-md-6"},
    {name: "partner1Surname", type: "text", x:2, y:3, layout:"col-md-6"},
    {name: "partner2VitalRecordId", type: "text", x:1, y:4, layout:"col-md-12"},
    {name: "partner2Firstname", type: "text", x:1, y: 5, layout:"col-md-6"},
    {name: "partner2Surname", type: "text", x:2, y: 5, layout:"col-md-6"},
    {name: "place", type: "text", x:1, y: 6, layout:"col-md-6"},
    {name: "declaredDate", type: "date", x:1, y: 6, layout:"col-md-6"},
    {name: "notes", type: "text", x:1, y:7, layout:"col-md-12"}
	];

export const displayUnionRecordForm = (id) => {
	let UnionRecordForm = createFormComponent(fields);
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <UnionRecordForm key={uuid} loadData={async () => loadFormData(id)} buttons={() => null}/>
	});
}

const loadFormData = async (id) => {
	return await rest.read('union-record', id).then(response => {
		let form = response;
		if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error");
        return form;
	})
}
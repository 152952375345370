import React, {useEffect} from "react";
import {v4 as uuidv4} from 'uuid';
import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { pojoMetadata } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import {geoMetadataLoader} from "../../metadata/GeoMetadataLoader";
import {Observable} from "../../../../auto/js/events/Observable";

    const countryObservable = new Observable();
    const regionNameObservable = new Observable();
    const stateNameObservable = new Observable();
    const localGovNameObservable = new Observable();
    const regAreaNameObservable = new Observable();
    const pollingUnitNameObservable = new Observable();

        const handlecountryChange = (value) => {
        countryObservable.publish(geoMetadataLoader.getChilds(value.key))
        }
        const handleregionNameChange = (value) => {
        regionNameObservable.publish(geoMetadataLoader.getChilds(value.key))
        }
        const handlestateNameChange = (value) => {
        stateNameObservable.publish(geoMetadataLoader.getChilds(value.key))
        }
        const handlelocalGovNameChange = (value) => {
        localGovNameObservable.publish(geoMetadataLoader.getChilds(value.key))
        }

const fields = [
    {
    name:"country",
    type:"autocomplete",
    x:1, y:0, layout:"col-md-6",
        metadata: () => geoMetadataLoader.getRootNodes(),
        handleChange:(data) => handlecountryChange(data),
        
	readOnly: true  
    },
    {
    name:"regionName",
    type:"autocomplete",
    x:1, y:1, layout:"col-md-6",
        metadata: () => "",
        handleChange:(data) => handleregionNameChange(data),
        observable: countryObservable
    },
    {
    name:"stateName",
    type:"autocomplete",
    x:1, y:2, layout:"col-md-6",
        metadata: () => "",
        handleChange:(data) => handlestateNameChange(data),
        observable: regionNameObservable
    },
    {
    name:"localGovName",
    type:"autocomplete",
    x:1, y:3, layout:"col-md-6",
        metadata: () => "",
        handleChange:(data) => handlelocalGovNameChange(data),
        observable: stateNameObservable
    },
    {
    name:"regAreaName",
    type:"autocomplete",
    x:1, y:4, layout:"col-md-6",
        metadata: () => "",
        
        observable: localGovNameObservable
    },
    {
    name:"pollingUnitName",
    type:"text",
    x:1, y:5, layout:"col-md-6",
    },
];

let LocationForm = createFormComponent(fields);

class FormComponent extends React.Component {
constructor(props) {
super(props);
this.myRef = React.createRef()
CAN_I_SWITCH.pickUpThePhone(this.listen);
this.state = {
closeRequested: undefined
}
}

componentDidMount() {
this.props.loadData().then(
rowdata => {
    countryObservable.publish(geoMetadataLoader.getChilds(rowdata?.country?.key));
    regionNameObservable.publish(geoMetadataLoader.getChilds(rowdata?.regionName?.key));
    stateNameObservable.publish(geoMetadataLoader.getChilds(rowdata?.stateName?.key));
    localGovNameObservable.publish(geoMetadataLoader.getChilds(rowdata?.localGovName?.key));
    regAreaNameObservable.publish(geoMetadataLoader.getChilds(rowdata?.regAreaName?.key));
}
)
}

listen = (closeMe) => {
if (!this.isDirty())
closeMe(true);
this.setState({closeRequested: closeMe})
}

isDirty = () => {
return this.myRef.current.isDirty();
}

handleDialogCancel = () => {
this.state.closeRequested(false);
this.setState({closeRequested: undefined});
}

handleSave = () => {
this.myRef.current.save().then(() => {
this.state.closeRequested(true);
});
}

handleDontSave = () => {
this.state.closeRequested(true);
}

render() {
return (
<>
<AlertDialog
        title={t`Save your changes ?`}
        open={(this.state.closeRequested && this.isDirty())?true:false}
        handleClose={this.handleDialogCancel}
        noAgree={true}
        save={this.handleSave}
        dontSave={this.handleDontSave}
/>
<LocationForm ref={this.myRef} key={this.props.key} loadData={() => this.props.loadData()} onSubmit={this.props.onSubmit} id={this.props.id} buttons={getButtons} />
</>
)
}

}

export const displayNewLocationForm = () => {
let uuid = uuidv4();
OPEN_VIEW_EVENT.publish({
uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(fields)} onSubmit={save}/>
    });
    }

    export const displayReadLocationForm = (rowdata) => {
    let uuid = uuidv4();
    OPEN_VIEW_EVENT.publish({
    uuid, view: () => <FormComponent key={uuid} loadData={() => Promise.resolve(rowdata)} onSubmit={update}
    id={rowdata.pollingUnitNameId}/>
    });
    }

    const loadFormData = (rowdata) => {
    return rowdata;
    }

    const save = async (formData) => {
    let dto = form2dto(formData);
    dto.id = null;
    dto.areaId = geoMetadataLoader.incrementLocationAreaIdFromParentId(dto.parentId);
    try {
    return rest.create('locations', dto).then((response) =>{
    if (response.status)
    showNotification(response.message.split('Detail: ')[1], "error")
    else {
    showNotification(t`Created Location`, "success");
    geoMetadataLoader.load();
    }
    });
    } catch (err) {
    alert(err);
    }
    }

    const update = async (formData) => {
    let dto = form2dto(formData);
    try {
    return rest.update('locations', dto).then((response) =>{
    if (response.status)
    showNotification(response.message.split('Detail: ')[1], "error")
    else {
    showNotification(t`Updated Location`, "success");
    geoMetadataLoader.load();
    }
    });
    } catch (err) {
    alert(err);
    }
    }


    const form2dto = (data) => {
    let updatedata = {};
    updatedata.id = ~~(data.id);
    updatedata.areaId = data.areaId;
    updatedata.name = data.pollingUnitName;
    updatedata.type = 6;
    updatedata.parentId = ~~(data.regAreaName?.key);
    if(updatedata.areaId == "")
    updatedata.areaId = null;
    if(updatedata.name == "")
    updatedata.name = null;
    return updatedata
    }

    const buildEmptyObject = (fields) => {
    const empty = {};
    for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    switch (field.type) {
    case ("text"):
    empty[field.name] = "";
    break;
    case ("number"):
    empty[field.name] = "";
    break;
    case ("checkbox"):
    empty[field.name] = false;
    break;
    case ("timestampz"):
    empty[field.name] = '';
    break;
    case ("date"):
    empty[field.name] = null;
    break;
    case ("select"): // dynamic lists, loaded from the backend
    empty[field.name] = '';
    break;
    case ("list"): // static lists, hardcoded
    empty[field.name] = '';
    break;
    case ("password"):
    empty[field.name] = '';
    break;
    }
    }
    return empty;
    }

    const getButtons = () => {
    return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
    }
import * as React from 'react';
import { useField } from "formik";
import { Observable } from '../../../auto/js/events/Observable';
import { geoMetadataLoader } from '../../../auto/js/metadata/GeoMetadataLoader';
import { t } from '../../../auto/js/services';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';

const countryObservable = new Observable();
const regionNameObservable = new Observable();
const resetRegionnameEvent = new Observable();
const localGovNameObservable = new Observable();
const resetLocalgovnameEvent = new Observable();
const resetRegareanameEvent = new Observable();

let resetList =[resetRegionnameEvent,resetLocalgovnameEvent,resetRegareanameEvent,];
export function AddressComponent({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [hideRegionname, setHideRegionname] = React.useState(false);
    const [hideLocalgovname, setHideLocalgovname] = React.useState(true);
    const [hideRegareaname, setHideRegareaname] = React.useState(true);

    let countryValue;
    const handleCountryChange = (value) => {
        if (value.key) {
            countryObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideRegionname(false);
            else
                setHideRegionname(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            countryObservable.publish([])
            setHideRegionname(true);
        }
        resetFieldsFromIndex(0);
    }
    let regionNameValue;
    const handleRegionnameChange = (value) => {
        if (value.key) {
            regionNameObservable.publish(geoMetadataLoader.getGeoLevel(value.key, 3))
            if (Object.keys(geoMetadataLoader.getGeoLevel(value.key, 3)).length)
                setHideLocalgovname(false);
            else
                setHideLocalgovname(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            regionNameObservable.publish([])
            setHideLocalgovname(true);
        }
        resetFieldsFromIndex(1);
    }
    let localGovNameValue;
    const handleLocalgovnameChange = (value) => {
        if (value.key) {
            localGovNameObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideRegareaname(false);
            else
                setHideRegareaname(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            localGovNameObservable.publish([])
            setHideRegareaname(true);
        }
        resetFieldsFromIndex(2);
    }
    let regAreaNameValue;
    const handleRegareanameChange = (value) => {
        if (value.key) {
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
    }
    
    const showData = (value) => {
	    let addressComponents = value.split(".")
	    if (addressComponents[0]) {
	        let country = geoMetadataLoader.getArea(addressComponents[0]);
	        countryValue = { key: country.id, value: country.name };
	        countryObservable.publish(geoMetadataLoader.getGeoLevel(country.id, 1))
	    }
	    if (addressComponents[1]) {
	        let regionName = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1]);
	        regionNameValue = { key: regionName.id, value: regionName.name };
	        regionNameObservable.publish(geoMetadataLoader.getGeoLevel(regionName.id, 3))
	    }
	    if (addressComponents[3]) {
	        let localGovName = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3]);
	        localGovNameValue = { key: localGovName.id, value: localGovName.name };
	        localGovNameObservable.publish(geoMetadataLoader.getGeoLevel(localGovName.id, 4))
	    }
	    if (addressComponents[4]) {
	        let regAreaName = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3] + "." + addressComponents[4]);
	        regAreaNameValue = { key: regAreaName.id, value: regAreaName.name };
	    }
	}
    
    if (value != null)
        showData(value);
    let style = (props.linear)?"row":"";
    return (
        <>
            <div class="mainform">
                <section class={"fullwidth " + style}>
                    	<SimpleAutoCompleteInput layout={(props.linear)?"col-md-3":""} name={"country"} options={() => geoMetadataLoader.getRootNodes()} label={t`Country`} handleChange={(data) => handleCountryChange(data)}  defaultValue={countryValue} disabled={props.disabled}/>
                    	{(!hideRegionname || regionNameValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-3":""} name={"regionName"} options={() => ""} label={t`Regionname`} handleChange={(data) => handleRegionnameChange(data)} observable={countryObservable} reset={resetRegionnameEvent} defaultValue={regionNameValue} disabled={props.disabled}/>}
                    	{(!hideLocalgovname || localGovNameValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-3":""} name={"localGovName"} options={() => ""} label={t`Localgovname`} handleChange={(data) => handleLocalgovnameChange(data)} observable={regionNameObservable} reset={resetLocalgovnameEvent} defaultValue={localGovNameValue} disabled={props.disabled}/>}
                    	{(!hideRegareaname || regAreaNameValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-3":""} name={"regAreaName"} options={() => ""} label={t`Regareaname`} handleChange={(data) => handleRegareanameChange(data)} observable={localGovNameObservable} reset={resetRegareanameEvent} defaultValue={regAreaNameValue} disabled={props.disabled}/>}
                </section>
            </div>
        </>
    )
}


const resetFieldsFromIndex = (index) => {
    for (let i = index; i < resetList.length; i++)
        resetList[i].publish()
}
import React, {useState} from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Facets from "../widgets/Facets";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";

import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { displayReadDeathRecordForm } from "../forms/DeathRecordForm";
import { DEATH_RECORD_ORDER_BY_FIRSTNAME } from "../../../auto/js/metadata/DeathRecordOrderBy";
import { getErrorList } from "../../../main/js/lists/DeathRecordListCommon";

const fields = [
    {title:"image", field:"image",  render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "id", field: "id"},
    {title: "firstname", field: "firstname"},
    {title: "secondname", field: "secondname"},
    {title: "thirdname", field: "thirdname"},
    {title: "fourthname", field: "fourthname"},
    {title: "dateOfDeath", field: "dateOfDeath"},
];

const DeathRecordListPage = ({uuid}) => {
	const[selectAllError, setSelectAllError] = useState(false);
	const [errorList, setErrorList] = useState([]);
	
    let DeathRecordsList = createTableComponent(fields);
    
    const onErrorFacetChange = (key, value) => {
	    let list = _.clone(errorList);
	    if (key === "selectAllError") {
	        if (value.target.checked)
	            list = selectAllErrors();
	        else
	            list = [];
	        setErrorList(list);
	        setSelectAllError(value.target.checked)
	    }
	    else if (value.target.checked && !list.includes(key)) {
	        list.push(key);
	        setErrorList(list);
	    }
	    else if (!value.target.checked && list.includes(key)) {
	        let index = list.indexOf(key);
	        list.splice(index, 1);
	        setErrorList(list);
	    }
	}
	
	const buildData = async (query) => {
	    let filter = query;
	    let data;
	    filter["death-record-document"] = {};
	    filter["orderBy"] = DEATH_RECORD_ORDER_BY_FIRSTNAME;
	    filter.orderDirection = null;
	    filter.offset = query.page * query.pageSize;
	    let deathRecordTags =[];
	    
	     errorList.forEach(element => {
	        deathRecordTags.push(element);
	    });
		filter["death-record-document"]["contentList"] =  deathRecordTags;
	    if (query.search && query.search!='') {
	        pojoMetadata["death-record"].columns.forEach(element => {
	            if(element.type=='text'){
	                filter["death-record-document"][element.key]= query.search;
	            }
	        });
	        filter["and"] = false;
			filter.fuzziness = "AUTO";
	    }
	    return await getCivilRecordData(filter).then(response => {
	        data = filterData(response)
	        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
	    });
	}
	
	const getCivilRecordData = async (filter) => {
		return await rest.request(getServiceUri() + "death-record/search-document/", "POST", filter);
	}
	
	const countData = async (filter) => {
		return await rest.request(getServiceUri() + "death-record/count-document/", "POST", filter);
	}
	
		const getErrorCodeFacets = () => {
	       let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
	       	getErrorList().forEach(error => {
	            list.push({key: error, value: errorList.includes(error), label: t(error)});
	
	        });
	        return list;
	    }
	    
	 	const buildFacets = (key, value, label, handleChange) => {
		    return (
		        <>
		        <FormControlLabel
		        control={<Checkbox
		          checked={value}
		          onChange={(event) => handleChange(key, event)}
		          color="primary"
		          value={value}
		        />
		        }
		        label={label}
		        style={{width: '100%'}}
		        />
		        </>
		    )
		}
	    
	    return (
	    <div>
	        <Facets title={t`Errors`} facets={getErrorCodeFacets()} facetsComponents={(key, value) => buildFacets(key, value, t(key), onErrorFacetChange)} />
	        <DeathRecordsList key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions()} />
	    </div>
		)
    
}

export const displayDeathRecordList = () => {
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () =>  <DeathRecordListPage uuid={uuid} />
	});
}

const selectAllErrors = () => {
 	let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let faceUrl = null;
        if (row.faceId != null) {
			faceUrl = getServiceUri() + "face/image/" + row.faceId;	
		}
        row.image = (faceUrl != null)?faceUrl:"/public/avatar.png";       
        let date = row.dateOfDeath
        if ( date !== null)
        row.dateOfDeath = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    displayReadDeathRecordForm(rowData.id);
                }
        }
    )
    return actions;
}
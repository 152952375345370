import * as React from 'react';
import { useField } from "formik";
import { Observable } from '../../../auto/js/events/Observable';
import { officeMetadataLoader } from '../../../main/js/metadata/OfficeMetadataLoader';
import { t } from '../../../auto/js/services';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';


const resetOfficeEvent = new Observable();
const conservatoireObservable = new Observable();

let resetList =[resetOfficeEvent,];
export function OfficeComponent({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [hideOffice, setHideOffice] = React.useState(true);

    let conservatoireValue;
    const handleConservatoireChange = (value) => {
        if (value.key) {
            conservatoireObservable.publish(officeMetadataLoader.getChilds(value.key))
            if (Object.keys(officeMetadataLoader.getChilds(value.key)).length)
                setHideOffice(false);
            else
                setHideOffice(true);
            setValue(officeMetadataLoader.getAreaId(value.key));
        }
        else {
            conservatoireObservable.publish([])
            setHideOffice(true);
        }
        resetFieldsFromIndex(0);
    }
   
    let officeValue;
    const handleOfficeChange = (value) => {
        if (value.key) {
            setValue(officeMetadataLoader.getAreaId(value.key));
        }
    }
    
    const showData = (value) => {
	    let addressComponents = value.split(".")
	    if (addressComponents[0]) {
	        let conservatoire = officeMetadataLoader.getArea(addressComponents[0]);
	        conservatoireValue = { key: conservatoire.id, value: conservatoire.name };
	        conservatoireObservable.publish(officeMetadataLoader.getGeoLevel(conservatoire.id, 1))
	    }
	    if (addressComponents[1]) {
	        let office = officeMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1]);
	        officeValue = { key: office.id, value: office.name };
	    }
	}
    
    if (value != null)
        showData(value);
    let style = (props.linear)?"row":"";
    return (
        <>
            <div class="mainform">
                <section class={"fullwidth " + style}>
                    	<SimpleAutoCompleteInput layout={(props.linear)?"col-md-6":""} name={"country"} options={() => officeMetadataLoader.getRootNodes()} label={t`Conservatoires`} handleChange={(data) => handleConservatoireChange(data)}  defaultValue={conservatoireValue} disabled={props.disabled}/>
                    	{(!hideOffice || officeValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-6":""} name={"Office"} options={() => ""} label={t`Office`} handleChange={(data) => handleOfficeChange(data)} observable={conservatoireObservable} reset={resetOfficeEvent} defaultValue={officeValue} disabled={props.disabled}/>}
                </section>
            </div>
        </>
    )
}


const resetFieldsFromIndex = (index) => {
    for (let i = index; i < resetList.length; i++)
        resetList[i].publish()
}
import React, { useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

import { setContextualOptions } from '../../../auto/js/widgets/RibbonTab'; 
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT, OPEN_SIDE_PANEL } from '../../../auto/js/events/Gui';
import { rest, t } from "../../../auto/js/services";
import { buildEmptyObject, getServiceUri, metadataLoader, pojoMetadata } from "../../../auto/js/metadata";
import { showNotification } from "../../../auto/js/utils";
import { certificateStyle } from "./CertificateStyleObj";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../widgets/FileUploader";

import { createTableComponent } from "../widgets/TableComponent";
import { civilRecordFormfields } from "../../../main/js/forms/CivilRecordFormCommon"
import { AttachmentsArea } from "../widgets";
import { BirthCertificate } from './BirthCertificate';
import * as Yup from 'yup';

import '../../css/printable.css';
import QRCode from "react-qr-code";
import { MTLB_TYPE_ID_CARD } from "../../../auto/js/metadata/MtlbType";
import { displayNewAmendmentApplicationForm } from "./amendmentApplication/NewAmendmentApplicationForm";
import { displayNewIdCardApplicationForm } from "./idCardApplication/NewIdCardApplicationForm";
import { Button } from "@mui/material";
import { NotesGrid } from "../../../auto/js/widgets/NotesGrid";
import { HistoryGrid } from "../../../auto/js/widgets/HistoryGrid";

const printBirthCertificate = (data) => () => {
	const printable = {
				content:<BirthCertificate data={data}/>,
				style:{certificateStyle}.certificateStyle,
				copyParentStyle:false
	}
	PRINT_EVENT.publish(printable);
}
export const displayReadCivilRecordForm = async (id) => {
	let CivilRecordForm = createFormComponent(civilRecordFormfields);
	let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid,
		view: () => {
			return (
				<>
					{/* <Button className="link-button" onClick={() => openHistorySidePanel(id)}>{t`History`}</Button> */}
					<Button className="link-button" onClick={() => openSidePanel(id)}>{t`Notes`}</Button>
					<CivilRecordForm key={uuid} loadData={async () => loadFormData(id,token)} readOnly buttons={() => null}/>
            		<AttachmentsArea loadData={async () => loadCivilRecordAttachment(id)} handleClick={(fileId) => handleCivilRecordAttachmentClick(fileId)}/>
				</>
			)
		}
	});
}

const handleCivilRecordAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'vital-record-attachment' + '/' + id + '/' + token;
};

const loadCivilRecordAttachment = async (id) => {
	let filter = {and: true};
	filter['vital-record-attachment'] = {};
	filter['vital-record-attachment']['vitalRecordId'] = id;
	return rest.search('vital-record-attachment', filter)
}

const openSidePanel = (id) => {
	let noteFilter = {and: true};
	noteFilter['vital-record-note'] = {vitalRecordId: id};
	rest.search('vital-record-note', noteFilter).then(notes => {
		OPEN_SIDE_PANEL.publish(
			{body: <NotesGrid list={notes}/>, title: t`Notes`}
		)
	})
}

const openHistorySidePanel = (id) => {
	let historyFilter = {and: true};
	historyFilter['vital-record-history'] = {id: id};
	rest.search('vital-record-history', historyFilter).then(histories => {
		OPEN_SIDE_PANEL.publish(
			{body: <HistoryGrid list={histories}/>, title: t`History`}
		)
	})
}

export const loadFormData = async (id,token) => {
	return await rest.read('vital-record', id).then(async response => {
		let form = response;
		if (response.faceId != null) {
			const imageUrl = getServiceUri() + 'face/image/' + response.faceId + "/" + token;
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (imageUrl != null) ? imageUrl : '/public/avatar.png', isEmpty: false };
		} else
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true };
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = { key: response.birthCountry, value: countryMetadata[response.birthCountry] }
		}
		let tagFilter = { and: true };
		tagFilter['vital-record-tag'] = { vitalRecordId: id };
			await rest.read('custom/get-birth-certificate-data', id).then(async data => {

			setContextualOptions({
			"books": {
				submenu: {
					"cr-form": {
						options: {
							"amendment": {
								label: "Amendment", do: () => displayNewAmendmentApplicationForm(form)
							},
							"birth-certificate": { 
								label: "Birth Certificate", do: printBirthCertificate(data)
							},
						}
					}
				}
			}
		})});
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('vital-record-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const certificateStyle = `
@charset "UTF-8";
@import url(https://fonts.cdnfonts.com/css/montserrat);
:root {
--bleed: 0.5cm;
--margin: 1cm;
}

@page {
size: A4 ;
margin: 0;
}
* {
box-sizing: border-box;
}
* {
-webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
color-adjust: exact !important;                 /* Firefox 48 – 96 */
print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}

body {
margin: 0 auto !important;
padding: 0 !important;
background: rgb(204, 204, 204);
--bleed: 0mm;
--margin: 10mm;

}
.page {
display: inline-block;
position: relative;
height: 206mm;
width: 297mm;
font-size: 12pt;
margin: 10mm auto;
padding: calc(var(--bleed) + var(--margin));
box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
background: white;
font-family: montserrat;
}
h1 {
font-size: 15pt !important;
font-weight: bold !important;
text-align: center;
margin: 0mm;
position: relative;
bottom: 4mm;
}
h2 {
font-size:9pt !important;
font-weight:bold !important;
}
.headercol5 h2 {
text-align: center;
margin-top: 0mm;
position: relative;
top:-4mm;
}
.headercol5 h1 {
text-align: center;
padding-bottom: 0mm !important;
}
h3 {
font-size:0.95em !important;
font-weight:bold !important;
margin: 0 !important;
}

p {
font-size:8pt;
font-weight:normal;
line-height: 13pt;
}
p.bottom {
margin-top: 0mm !important;
}
.lefttext {
text-align: left;
}
.righttext {
text-align: right;
}
.idnumber1 {
margin: 0mm;
position: relative;
bottom: -2mm;
font-size:8pt;
font-weight:normal;
line-height: 13pt;
}

.pattern {
margin: 10mm calc(-1mm - var(--margin));
}

.header {
margin: calc(0mm - var(--margin))!important;
background-color: #ce1126;
/*position: relative;
top: -6mm !important;*/
padding: 4mm 10mm;
border-bottom: 0.75mm solid #000000;
display: flex;
flex-direction:row;
flex-wrap: wrap;
height:65mm;
}
.header::after {
position: absolute;
content: '';
top: 65mm;
left: 0;
width: 100%;
height: 10mm;
margin: 0;
pointer-events: none;
z-index: 9999;
background-image: url(https://cr.gw.chahinehamila.com/public/birth-certificate-pattern.svg);
background-repeat: no-repeat;
}
.headercol1 {
height:90%;
margin:0mm 10mm 0mm 0mm;
flex-basis: 75mm;
}

.headercol2 {
margin:0mm;
flex-basis: 107mm;
}
.headercol3 {
margin:0mm 0mm 0mm 10mm ;
height:90%;
flex-basis: 75mm;
}
.headercol4 {
height:10%;
margin:0mm 10mm 0mm 0mm;
flex-basis: 75mm;
}
.headercol5 {
margin:0mm;
flex-basis: 107mm;
height:10%;
}
.headercol6 {
margin:0mm 0mm 0mm 10mm ;
height:10%;
flex-basis: 75mm;
}

.logos {
background-image: url(https://cr.gw.chahinehamila.com/public/logos.png);
background-repeat: no-repeat;
background-size: contain;
margin:-2mm auto;
text-aligh:center;
background-position:bottom 50%;
height:49mm !important;
}
.clear {clear:both;}
.bodycontent {
margin-top: 25mm;
display: flex;
flex-wrap: wrap;
}
.bodycol1 {
height:80mm;
margin:0mm 10mm 0mm 0mm;
flex-basis: 87.333mm;
}

.bodycol2 {
height:80mm;
margin:0mm;
flex-basis: 87.333mm;
}
.bodycol3 {
height:80mm;
margin:0mm 0mm 0mm 10mm ;
flex-basis: 82.333mm;
}
.bodycol4 {
height:41mm;
margin:0mm 10mm 0mm 0mm;
flex-basis: 87.333mm;
}
.bodycol5 {
height:41mm;
margin:0mm;
flex-basis: 87.333mm;
}
.bodycol6 {
height:41mm;
margin:0mm 0mm 0mm 10mm ;
flex-basis: 82.333mm;
}
.nestedcol1 {
width:36%;
height:85%;
margin: 0 2% 10mm 0;
/*background-color: #999999;*/
float:left;
font-weight: bold;
}
.nestedcol1 p{
font-weight: bold;
font-size:8pt;
line-height: 13pt;
}
.nestedcol2 p{
font-weight: normal;
font-size:8pt;
line-height: 13pt;
}
.nestedcol1 p:first-child, .nestedcol2 p:first-child{
margin-top:0mm !important;
}
.nestedcol2 {
width:62%;
height:85%;
margin: 0 0 10mm 0;
/*background-color: #313131;*/
float:left;
}
.official {
/*postion: relative;*/
display: flex;
height:25mm;
}
.qr-code {
postion: relative;
top:0mm;
left:0mm;
width: 22mm;
height:22mm;
margin-bottom:2mm;
}
.stamp {
position: relative;
top:-8mm;
left:20mm;
width: 32mm;
height:32mm;
margin-bottom:2mm;
background-image:url(https://cr.gw.chahinehamila.com/public/offical-approved-stamp.svg);
background-size: contain;
}
.signature {
position: absolute;
z-index:9999;
bottom:30mm;
right:30mm;
width: 32mm;
height:15mm;
margin-bottom:2mm;
background-image:url(https://cr.gw.chahinehamila.com/public/signature-temp.png);
background-size: contain;
background-repeat: no-repeat;
}
.strongstyle{
margin-top:1.5mm !important;
line-height:10pt;
font-size: 8pt;
font-weight: normal;
}

@media screen {
.page::after {
position: absolute;
content: '';
top: 0;
left: 0;
width: calc(100% - var(--bleed) * 2);
height: calc(100% - var(--bleed) * 2);
margin: var(--bleed);
outline: thin dashed black;
pointer-events: none;
z-index: 9999;
}
.printable-area {
display: none;
}
}

@media print {
.page {
margin: 0;
overflow: hidden;


}
body {
margin: 0 auto !important;
padding: 0 !important;

}
html {
margin: 0 ;

}

* {
-webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
color-adjust: exact !important;                 /* Firefox 48 – 96 */
print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}

}`;

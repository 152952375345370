import React from 'react';

import { loadFormData } from "../../auto/js/forms/CivilRecordForm";
import { createFormComponent } from "../../auto/js/widgets";
import { civilRecordFormfields } from "./forms/CivilRecordFormCommon";
import { rest } from '../../auto/js/services';
import { getServiceUri } from '../../auto/js/metadata';
import PictureComponent from '../../auto/js/widgets/PictureComponent';

const viewVitalRecord = (args) => { 
	let CivilRecordForm = createFormComponent(civilRecordFormfields);
	return () => <CivilRecordForm loadData={async () => loadFormData(args.id)} readOnly buttons={() => null}/>

}

const loadImage = async (id) => {
	//const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return rest.loadImage(getServiceUri() + 'civil-status-mtlb-attachment/content/' + id)
}

const showPicture = (args) => {
	return <PictureComponent loadData={async () => loadImage(args.id)} />
}

export const subcommandOptions = {
	viewvr: {
	  	id: 'number'
	},
	showPicture: {
		id: 'number'
	}
};

export const commandHooks = {
	viewvr: viewVitalRecord,
	showPicture: showPicture
}

import React from "react";
import {v4 as uuidv4} from 'uuid';

import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { pojoMetadata } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";

const fields = [
    {name:"messageId", type:"text", x:1, y:1, layout:"col-md-3"},
    {name:"value", type:"text", x:2, y:1, layout:"col-md-3"},
    {name:"localeId", type:"text", x:3, y:1, layout:"col-md-3"},
];

let TranslationsForm = createFormComponent(fields);
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<TranslationsForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={getButtons} />
		</>
		)
	}

}

// export const displayNewTranslationsForm = (gui) => {
// 	const readNewTranslationsForm = (onFinish) => (args) => {
// 		let uuid = uuidv4();
// 		return {
// 			uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(fields)} onSubmit={(onFinish)?(data) => save(data).then(() => onFinish()):save}/>
// 		};
// 	}
// 	gui.goTo(readNewTranslationsForm());
// }

export const displayNewTranslationsForm = () => {
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(fields)} onSubmit={save}/>
	});
}
// export const displayReadTranslationsForm = (onFinish) => (id) => {
// 	let uuid = uuidv4();
// 	return {
// 		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
// 	};
// }
export const displayReadTranslationsForm = (id) => {
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={update} id={id}/>
	});
}

const loadFormData = async (id) => {
	return await rest.read('i18n', id).then(response => {
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		return response;
	})
}

const save = async (formData) => {
	let dto = pojoMetadata['i18n'].form2dto(formData);
    dto.domainId = '-';
    try {
        return rest.create('i18n', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created Translation`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

const update = async (formData) => {
	let dto = pojoMetadata['i18n'].form2dto(formData);
    dto.domainId = '-';
    try {
        return rest.update('i18n', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Translation`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
		}
	}
	return empty;
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}
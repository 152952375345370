import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import {createTableComponent, deleteItem} from "../../../../auto/js/widgets/TableComponent";
import { displayReadLocationForm} from "../../forms/metadata/LocationsForm";
import {geoMetadataLoader} from "../../metadata/GeoMetadataLoader";
import ReactDOM from "react-dom";
import MaterialTable from "material-table";
import {pojoMetadata} from "../../../../auto/js/metadata";
import {showNotification} from "../../../../auto/js/utils";

const fields = [
    {title: "country", field: "country"},
    {title: "regionName", field: "regionName"},
    {title: "stateName", field: "stateName"},
    {title: "localGovName", field: "localGovName"},
    {title: "regAreaName", field: "regAreaName"},
    {title: "pollingUnitName", field: "pollingUnitName"},
];

export const displayLocationsList = () => {
let LocationsList = createTableComponent(fields);
let uuid = uuidv4();
OPEN_VIEW_EVENT.publish({
uuid, view: () => <LocationsList key={uuid} localData={true} loadData={geoMetadataLoader.locationsdata} editable={getTableEditable()} actions={getTableActions()} />
});
}

export const getTableEditable = () => {
let editables = {};
editables.onRowDelete = rowData =>
new Promise((resolve, reject) => {
try {
return rest.delete('locations', ~~rowData.pollingUnitNameId).then((response) =>
{
if (response.status != 200 && response.status != 204) {
showNotification(response.message?.split('Detail: ')[1], "error");
reject();
}
else {
showNotification(t`Deleted Location`, "success");
geoMetadataLoader.load();
resolve()
}
})
} catch (err) {
alert(err);
reject();
}
})
return editables;
}

const getTableActions = () => {
let actions = [];
actions.push(
{
icon: () => <VisibilityIcon/>,
tooltip: t`Edit`,
onClick: (event, rowData) => {
displayReadLocationForm({
        "country": {key: rowData.countryId, value: rowData.country},
        "regionName": {key: rowData.regionNameId, value: rowData.regionName},
        "stateName": {key: rowData.stateNameId, value: rowData.stateName},
        "localGovName": {key: rowData.localGovNameId, value: rowData.localGovName},
        "regAreaName": {key: rowData.regAreaNameId, value: rowData.regAreaName},
        "pollingUnitName": rowData.pollingUnitName,
        "id": rowData.pollingUnitNameId,
"areaId": rowData.areaId
});
}
}
);
return actions;
}
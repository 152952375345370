import { Observable } from "./Observable";
import { Talifoon } from "./Talifoon";

export const FAILED_CONNECTION = 2;

/******* EVENTS *************/

export const GUI_LANGUAGE_CHANGE_EVENT = new Observable();
export const OPEN_VIEW_EVENT = new Observable();
export const CLOSE_VIEW_EVENT = new Observable();
export const OPEN_SIDE_PANEL = new Observable();
export const UPDATE_RIBBON_EVENT = new Observable();
export const CAN_I_SWITCH = new Talifoon();
export const FILE_UPLOADED_EVENT = new Observable();
export const DELETE_ATTACHMENT_EVENT = new Observable();
export const PRINT_EVENT = new Observable();
/******* REDUX STATE CHANGES **************/

export const getFailedConnetion = (failedConnection) => ({
	type: FAILED_CONNECTION,
	payload: failedConnection
});

export const guiReducer = (state = {}, action) => {
	if (action.type == FAILED_CONNECTION) {
		return {...state, failedConnection: action.payload}
	}
	return state;
}
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { VITAL_RECORD_ORDER_BY_FIRSTNAME } from "../../../auto/js/metadata/VitalRecordOrderBy";
import { rest } from "../../../auto/js/services";
import React from "react";


export const civilRecordColumns = [
    {title:"image", field:"image", render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "id", field: "id"},
    {title: "firstname", field: "firstname"},
    {title: "secondname", field: "secondname"},
    {title: "thirdname", field: "thirdname"},
    {title: "fourthname", field: "fourthname"},
    {title: "birthdate", field: "birthdate"},
];

export const buildData = async (query) => {
    let filter = query;
    let data;
    filter["vital-record"] = {inactiveList: [false]};
    filter["orderBy"] = VITAL_RECORD_ORDER_BY_FIRSTNAME;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["vital-record"].columns.forEach(element => {
            if(element.type=='text'){
                filter["vital-record"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getCivilRecordData(filter).then(response => {
        data = filterData(response)
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const getCivilRecordData = async (filter) => {
    return await rest.request(getServiceUri() + "vital-record/search-by-active/", "POST", filter);
}

const countData = async (filter) => {
    return await rest.request(getServiceUri() + "vital-record/count/search-by-active/", "POST", filter['vital-record']);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let faceUrl = null;
        if (row.faceId != null) {
            faceUrl = getServiceUri() + "face/image/" + row.faceId;
        }
        row.image = (faceUrl != null)?faceUrl:"/public/avatar.png";
        let date = row.birthdate
        if (date !== null)
            row.birthdate = date[2] + '-' + date[1] + '-' + date[0];
        row.id = formatId(row.id.toString())
        newRows.push(row);
    }
    return newRows;
}

function formatId(id) {
    if (id.length < 9) {
        let diff = 9 - id.length
        while (diff !== 0) {
            id = "0" + id;
            diff = diff - 1;
        }
    }
    let newString = id.slice(0, 3) + '-' + id.slice(3);
    let finalForm = newString.slice(0, 7) + '-' + newString.slice(7)
    return finalForm
}

export const getErrorList = () => {
    let list = [];
    for (let i = 1; i < 14; i++) {
        let code = (i<10)?"E" + "0" + i : "E" + i
        list.push(code);
    }
    return list;
}
import React from "react";
import {v4 as uuidv4} from 'uuid';

import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from '../../events/Gui';
import { rest, t } from "../../services";
import { pojoMetadata } from "../../metadata";
import { showNotification } from "../../utils";
import { AlertDialog } from "../../widgets/Dialogs";
import { createFormComponent } from "../../widgets/FormComponent";
import { geoMetadataLoader } from "../../metadata/GeoMetadataLoader";

const fields = [
		{name:"image", type:"image", x:1, y:1, layout:"col-md-12"},
		{name: "name", type: "text", x:1, y:2, layout:"col-md-12"},
	];

let CountryForm = createFormComponent(fields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<CountryForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={getButtons} />
		</>
		)
	}

}

export const displayReadCountryForm = (id) => {
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <FormComponent key={uuid} id={id} loadData={async () => loadFormData(id)} onSubmit={update}/>
	});
}

export const displayNewCountryForm = () => {
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(fields)} onSubmit={save}/>
	});
}

const save = async (formData) => {
	let dto = pojoMetadata['locations'].form2dto(formData);
	dto.areaId = geoMetadataLoader.getNewCountryAreaId();
	dto.type = 1;
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let flagMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.flag = base64;
		dto.flagMimeType = flagMimeType;
	}
    try {
        return rest.create('locations', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created Country`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

const update = async (formData) => {
	let dto = pojoMetadata['locations'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let flagMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.flag = base64;
		dto.flagMimeType = flagMimeType;
	}
    try {
        return rest.update('locations', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Country`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
		}
	}
	empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/flag.png', isEmpty: true};
	return empty;
}

const loadFormData = async (id) => {
	return await rest.read('locations', id).then(response => {
		let form = response;
		let flag = null;
		if (response.flag != null) {
			let mimeType = response['flagMimeType'];
			flag = "data:".concat(mimeType, ";base64,", response.flag)	
		}	
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (flag != null)?flag: '/public/flag.png', isEmpty: true};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		return form;
	})
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}
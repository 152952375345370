import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { getMtlbStatus, MtlbStatus, MTLB_STATUS_ARCHIVED, MTLB_STATUS_MANUAL_CHECK, MTLB_STATUS_READY_FOR_APPROVAL, MTLB_STATUS_REJECTED } from "../../../../auto/js/metadata/MtlbStatus";
import { getMtlbType, MtlbType } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { deathRegistrationListColumns, getData, getMtlbTypeList, getOrderBy, countData, filterData, getErrorList } from "../../../../main/js/lists/deathRegistration/DeathRegistrationListCommon"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import { displayReadApprovedDeathRegistrationForm } from "../../../../auto/js/forms/deathRegistration/ApprovedDeathRegistrationForm";
import { displayReadPendingDeathRegistrationForm } from "../../forms/deathRegistration/PendingDeathRegistrationForm";
import { displayReadReadyDeathRegistrationForm } from "../../forms/deathRegistration/ReadyDeathRegistrationForm";
import { displayReadRejectedDeathRegistrationForm } from "../../forms/deathRegistration/RejectedDeathRegistrationForm";


const DeathRegistrationListPage = ({uuid}) => {
    const [approved, setApproved] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [ready, setReady] = useState(false);
    const [pending, setPending] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const[selectAllStatus, setSelectAllStatus] = useState(false);
    const[selectAllError, setSelectAllError] = useState(false);

    let DeathRegistrationTable = createTableComponent(deathRegistrationListColumns);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("approved"):
                setApproved(value.target.checked);
                break;
            case ("pending"):
                setPending(value.target.checked);
                break;
            case ("rejected"):
                setRejected(value.target.checked);
                break;
            case ("ready"):
                setReady(value.target.checked);
                break;
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
            	setApproved(value.target.checked);
            	setPending(value.target.checked);
            	setRejected(value.target.checked);
            	setReady(value.target.checked);
				break;
        }
    }
    
       const buildData = async (query) => {
        let filter = query;
        let data;
        let mtlbStatusList = [];
        let tags =[];
        if (approved)
            mtlbStatusList.push(MTLB_STATUS_ARCHIVED);
        if (pending)
            mtlbStatusList.push(MTLB_STATUS_MANUAL_CHECK);
        if (rejected)
            mtlbStatusList.push(MTLB_STATUS_REJECTED);
        if (ready)
            mtlbStatusList.push(MTLB_STATUS_READY_FOR_APPROVAL);
        if (!approved && !pending && !rejected && !ready)
            mtlbStatusList = [MTLB_STATUS_ARCHIVED, MTLB_STATUS_MANUAL_CHECK, MTLB_STATUS_REJECTED, MTLB_STATUS_READY_FOR_APPROVAL]
        errorList.forEach(element => {
            tags.push( {content: element} )
        });
        filter["civil-status-mtlb-document"] = {mtlbTypeList: getMtlbTypeList(), mtlbStatusList: mtlbStatusList};
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["civil-status-mtlb"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["civil-status-mtlb-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            data = filterData(response)
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    }
    
    const onErrorFacetChange = (key, value) => {
        let list = _.clone(errorList);
        if (key === "selectAllError") {
            if (value.target.checked)
                list = selectAllErrors();
            else
                list = [];
            setErrorList(list);
            setSelectAllError(value.target.checked)
        }
        if (value.target.checked && !list.includes(key)) {
            list.push(key);
            setErrorList(list);
        }
        else if (!value.target.checked && list.includes(key)) {
            let index = list.indexOf(key);
            list.splice(index, 1);
            setErrorList(list);
        }
    }
    
    const getErrorCodeFacets = () => {
      let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
        getErrorList().forEach(error => {
            list.push({key: error, value: errorList.includes(error), label: t(error)});

        });
        return list;
    }
    
    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <Facets title={t`Application Status`} facets={[{key: "selectAllStatus", value: selectAllStatus, separator: true}, { key: "approved", value: approved }, { key: "pending", value: pending }, { key: "rejected", value: rejected }, { key: "ready", value: ready }]} facetsComponents={(key, value) => buildFacets(key, value, t(key), onFacetChange)} />
            <Facets title={t`Errors`} facets={getErrorCodeFacets()} facetsComponents={(key, value) => buildFacets(key, value, t(key), onErrorFacetChange)} />
            <DeathRegistrationTable key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions()} />
    	</div>
    )


}

export const displayDeathRegistrationList = () => {  
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <DeathRegistrationListPage key={uuid} />
	});
}

const selectAllErrors = () => {
    let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    switch(rowData.mtlbStatus) {
                        case (MTLB_STATUS_ARCHIVED):
                            displayReadApprovedDeathRegistrationForm(rowData.id);
                            break;
                        case (MTLB_STATUS_MANUAL_CHECK):
                            displayReadPendingDeathRegistrationForm(rowData.id);
                            break;
                        case (MTLB_STATUS_READY_FOR_APPROVAL):
                            displayReadReadyDeathRegistrationForm(rowData.id);
                            break;
                        case (MTLB_STATUS_REJECTED):
                            displayReadRejectedDeathRegistrationForm(rowData.id);
                            break;
                    }
                }
        }
    )
    return actions;   
}

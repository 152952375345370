import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import {createTableComponent} from "../../../../auto/js/widgets/TableComponent";
import {officeMetadataLoader} from "../../metadata/OfficeMetadataLoader";
import { displayReadConservatoireForm } from "../../forms/metadata/ConservatoireForm";
import { showNotification } from "../../../../auto/js/utils";

const fields = [
    {title: "conservatoire", field: "conservatoire"},
];

export const displayConservatoireList = () => {
    let LocationsList = createTableComponent(fields);
    let uuid = uuidv4();
    OPEN_VIEW_EVENT.publish({
        uuid, view: () => <LocationsList key={uuid} localData={true} loadData={loadConservatoireList()} editable={getTableEditable()} actions={getTableActions()} />
    });
}

const getTableEditable = () => {
    let editables = {};
    editables.onRowDelete = rowData =>
    new Promise((resolve, reject) => {
        try {
            let dto = form2dto(rowData);
            return rest.update('office', dto).then((response) =>
            {
                if (response.status) {
                    showNotification(response.message?.split('Detail: ')[1], "error");
                    reject();
                }
                else {
                    showNotification(t`Deleted Conservatoire`, "success");
                    officeMetadataLoader.load().then(() => resolve());
                }
            })
        } catch (err) {
            alert(err);
            reject();
        }
    }).then(() => displayConservatoireList());
    return editables;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
        icon: () => <VisibilityIcon/>,
        tooltip: t`Edit`,
        onClick: (event, rowData) => {
            displayReadConservatoireForm({
                "conservatoire": rowData.conservatoire,
                "id": rowData.conservatoireId,
                "areaId": rowData.areaId
            });
        }
        }
    );
    return actions;
}

const form2dto = (data) => {
	let updatedata = {};
	updatedata.id = ~~(data.conservatoireId);
	updatedata.areaId = data.areaId;
	updatedata.name = data.conservatoire;
	updatedata.type = 1;
	if(updatedata.areaId == "")
		updatedata.areaId = null;
	if(updatedata.name == "")
		updatedata.name = null;
    updatedata.disabled = true;
	return updatedata
}

const loadConservatoireList = () => {
    let conservatoireList = [];
    let conservatoireObject = officeMetadataLoader.getRootNodes();
    Object.keys(conservatoireObject).forEach(el => {
        conservatoireList.push({conservatoireId: el, conservatoire: conservatoireObject[el], areaId: officeMetadataLoader.getAreaId(el)});
    })
    return conservatoireList;
}
import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from "lodash";
import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { AlertDialog } from "../../widgets";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { saveAmendmentApplicationForm, updateAmendmentApplicationForm, deleteAmendmentApplicationForm, loadAmendmentApplicationFormData } from "./AmendmentApplicationApi";
import { buildAmendmentApplicationFormEmptyObject, amendmentApplicationFields } from "../../../../main/js/forms/amendmentApplication/AmendmentApplicationFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../../widgets/FileUploader";
import { createTableComponent } from "../../widgets/TableComponent";


export class AmendmentApplicationFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = (id) => {
		deleteAmendmentApplicationForm(id);
		this.state.closeRequested(true);
	}

	render() {
	let NewAmendmentApplicationForm = createFormComponent(this.props.fields);

		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<NewAmendmentApplicationForm ref={this.myRef} key={this.props.key} id={this.props.id} loadData={async () => loadAmendmentApplicationFormData(this.props.id)} onSubmit={this.props.onSubmit} buttons={getButtons} />
		</>
		)
	}

}


export const displayNewAmendmentApplicationForm = (formData) => {
	let data;
	if (!formData)
		data = buildAmendmentApplicationFormEmptyObject();
	else{
		data = _.clone(formData);
		if (!formData.image.isEmpty) {
			let base64Image = formData.image.url;
			fetch(base64Image)
				.then(res => res.blob())
				.then(blob => {				
					let faceMimeType = "image/png";
					data.face = blob;
					data.faceMimeType = faceMimeType;
					data.vitalRecordId = formData.id;
					data.id = null;
					
					const reader = new FileReader();
					reader.onloadend = () => {
						data.image.url = reader.result;
						
						saveAmendmentApplicationForm(data).then((response) => {
							let uuid = uuidv4();
							OPEN_VIEW_EVENT.publish({
								uuid,
								view: () => <AmendmentApplicationFormComponent key={uuid} id={response} onSubmit={save} fields={amendmentApplicationFields} />
							});
						});
					};
					reader.readAsDataURL(blob);
				});
		}
		else
		{
				data.vitalRecordId = formData.id;
				data.id = null;
				saveAmendmentApplicationForm(data).then((response) => {
					let uuid = uuidv4();
					OPEN_VIEW_EVENT.publish({
						uuid,
						view: () => <AmendmentApplicationFormComponent key={uuid} id={response} onSubmit={save} fields={amendmentApplicationFields} />
					});
				});
		}
	}
		
}

const save = async (formData) => {
	formData.draft = false;
	return updateAmendmentApplicationForm(formData).then((response) =>{
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Created New AmendmentApplicationForm`, "success");
	});
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}

import React from "react";
import _ from 'lodash';
import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_BIRTH_REGISTRATION } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import * as Yup from 'yup';
import {formState} from "../../../../auto/js/forms/FormState";
import { AddressComponent } from "../../../../auto/js/widgets/AddressComponent";
import { OfficeComponent } from "../../../../main/js/widgets/OfficeComponent";
import { PersonComponent } from "../../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../../utils";


const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};


export const birthRegistrationFields = [
	{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "image", type:"image", x:1, y:2, layout:"col-md-12"},
	{name: "office", type: "custom", x:1, y:3, layout: "col-md-12", component: (name, disabled) => <OfficeComponent name={name} disabled={disabled} linear={true}/>},
	{name: "birthdate", type: "date", x:1, y: 6, layout:"col-md-6",
		"validation": Yup.date().nullable().default(undefined).required('A date of birth is required')
	},
	{name: "birthTime", type: "time", x:2, y:6, layout:"col-md-6"},
	{name: "birthPlace", type: "custom", x:1, y:8, layout:"col-md-12", component: (name, disabled) => <AddressComponent name={name} disabled={disabled} linear={true}/>},
	{name: "childDetails", type: "custom", x:1, y:9, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "gender", type: "select", x:1, y: 10, layout:"col-md-6", metadata: () => gender,
		"validation": Yup.string().nullable().default(undefined).required(t`Gender is required`)},
    {name: "firstname", type: "text", x:1, y:11, layout:"col-md-6", 
		"validation": Yup.string().nullable().default(undefined).min(1, t`First name must be at least one character long`).max(40, t`First name must be less than fourty characters long`).required(t`First name is required`)},
    {name: "secondname", type: "text", x:2, y:11, layout:"col-md-6", 
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Second name must be at least one character long`).max(40, t`Second name must be less than fourty characters long`)},
    {name: "thirdname", type: "text", x:1, y: 12, layout:"col-md-6", 
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Family name must be at least one character long`).max(40, t`Family name must be less than fourty characters long`).required(t`Family name is required`)},
    {name: "fourthname", type: "text", x:2, y: 12, layout:"col-md-6",
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Third name must be at least one character long`).max(40, t`Third name must be less than fourty characters long`)},
	{name: "fifthname", type: "text", x:1, y: 13, layout:"col-md-6",
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Marriage name must be at least one character long`).max(40, t`Marriage name must be less than fourty characters long`)},
	{name: "sixthname", type: "text", x:2, y: 13, layout:"col-md-6",
	    "validation": Yup.string().nullable().default(undefined).min(1, t`Marriage name must be at least one character long`).max(40, t`Marriage name must be less than fourty characters long`)},
	{name: "fatherDetails", type: "custom", x:1, y:14, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "fatherId", type: "custom", x:1, y:15, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "reportedFatherName", type: "text", x:1, y:18, layout:"col-md-12"},
	{name: "motherDetails", type: "custom", x:1, y:19, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "motherId", type: "custom", x:1, y:20, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "reportedMotherName", type: "text", x:1, y:21, layout:"col-md-12"},
	{name: "", type: "custom", x:1, y:22, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "reportedPaternalGrandfatherName", type: "text", x:1, y:23, layout:"col-md-12"},
	{name: "reportedPaternalGrandmotherName", type: "text", x:1, y:24, layout:"col-md-12"},
	{name: "reportedMaternalGrandfatherName", type: "text", x:1, y:25, layout:"col-md-12"},
	{name: "reportedMaternalGrandmotherName", type: "text", x:1, y:26, layout:"col-md-12"},
	{name: "medicalDetails", type: "custom", x:1, y:27, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "medicalNotification", type: "checkbox", x:1, y:28, layout:"col-md-12"},
	{name: "witness1Id", type: "custom", x:1, y:29, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData} />},
    {name: "witness2Id", type: "custom", x:2, y:30, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData} />},
	{name: "declarantDetails", type: "custom", x:1, y:31, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "declarantId", type: "custom", x:1, y:32, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "extras", type: "custom", x:1, y:33, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "notes", type: "text", x:1, y:34, layout:"col-md-12"},
];

const checkAttachment = () => {
	var attachments = formState.getAttachmentList();
	return attachments.length;
}

export const saveBirthRegistrationForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
    dto.mtlbType = MTLB_TYPE_BIRTH_REGISTRATION;
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
    try {
		return rest.request(getServiceUri() + 'apply/create-civil-status-mtlb', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadBirthRegistrationFormData = async (id) => {
	return await rest.read('civil-status-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)			
		}
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = {key: response.birthCountry, value: countryMetadata[response.birthCountry]}
		}
        if (response.birthTime !== null) {
			response.birthTime = new Date().setHours(response.birthTime[0], response.birthTime[1]);
		}

		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};
		let tagFilter = {and: true};
		tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('civil-status-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingBirthRegistrationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Birth Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateBirthRegistrationForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.update('civil-status-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created Birth Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteBirthRegistrationForm = async (id) => {
    try {
        return rest.delete('civil-status-mtlb', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Deleted birth registration application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedBirthRegistrationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Birth Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectBirthRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyBirthRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyBirthRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const buildBirthRegistrationFormEmptyObject  = () => {
    const empty = buildEmptyObject(birthRegistrationFields);
    empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/avatar.png', isEmpty: true};
	empty['birthCountry'] = {key: 191, value: 'Vanuatu'}
	return empty;
}

export const loadCivilStatusMtlbAttachment = async (id) => {
	let filter = {and: true};
	filter['civil-status-mtlb-attachment'] = {};
	filter['civil-status-mtlb-attachment']['civilStatusMtlbId'] = id;
	return rest.search('civil-status-mtlb-attachment', filter)
}

export const form2dto = (formData, dto) => {
	if(formData.birthdate != null && typeof(formData.birthdate) != 'string') {
		let birthdateAsDate = new Date(formData.birthdate);
		dto.birthdate = birthdateAsDate.getFullYear() + "-" + ('0' + (birthdateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + birthdateAsDate.getDate()).slice(-2);
	}
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
	if (formData.birthTime != null && typeof(formData.birthTime) != 'string') {
		const date = new Date(formData.birthTime)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.birthTime = birthHour + ":" + birthMinute;
	}

}

export const dto2form = (response) => {
	let form = response;
	let face = null;
	if (response.face != null) {
		let mimeType = response['faceMimeType'];
		face = "data:".concat(mimeType, ";base64,", response.face)			
	}
	if (response.birthCountry != null) {
		let countryMetadata = metadataLoader.get('country');
		form.birthCountry = {key: response.birthCountry, value: countryMetadata[response.birthCountry]}
	}
	if (response.birthTime !== null) {
		response.birthTime = new Date().setHours(response.birthTime[0], response.birthTime[1]);
	}

	form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: (face == null)};
	
	return form;
}

const Section = ({name}) => {
	return (
		<>
		<div class="col-md-12 sectiontitle">
			<h2 style={{ display: "inline" }}>{t(name)}</h2>
		</div>
		</>
	)
}
import { getServiceUri } from "../metadata";
import Keycloak from 'keycloak-js';

class RestClient {

	static oidcService;

	constructor() {
		this.oidcService = new Keycloak({
			url: 'https://cr.gw.chahinehamila.com/auth',
			realm: 'master',
			clientId: 'archide-core'
		});
	}
	
	request = async (uri, method, data) => {
		let json = JSON.stringify(data);
		const response = await fetch(uri, {
			method: method,
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: (data)?json:null
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}	
	
	// TODO: all methods below should be eventually removed or cleaned up

	create = async (subsection, data) => {
		let json = JSON.stringify(data);
		const response = await fetch(getServiceUri() + '/' + subsection, {
			method: 'POST',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: json
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	read = async (subsection, id) => {
		const response = await fetch(getServiceUri() + '/' + subsection + '/' + id, {
			method: 'GET',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer' // no-referrer, *client
		});
		if (response.status===401)
			this.oidcService.login();
		else if (response.status===200)
			return await response.json();
		else
			return response;
	}

	count = async (subsection, filter) => {
		let json;
		if (filter)
			json = JSON.stringify(filter);
		else
			json = JSON.stringify({});
		const response = await fetch(getServiceUri() + '/' + subsection + '/count/', {
			method: 'POST',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: json
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	getAttachment = async (subsection, id) => {
		const response = await fetch(getServiceUri() + '/' + subsection + '-attachment/' + id, {
			method: 'GET',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer' // no-referrer, *client
		});
		if (response.status===401)
			this.oidcService.login();
		return response;
	}

	getReport = async (reportId, filter) => {
		let json = JSON.stringify(filter);
		const response = await fetch(getServiceUri() + '/report/' + reportId, {
			method: 'POST',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			body: json,
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer' // no-referrer, *client
		});
		if (response.status===401)
			this.oidcService.login();
		response.blob().then(
			function(url) {
				const ObjectUrl = URL.createObjectURL(url)
				const a = document.createElement('a');
				document.body.appendChild(a);
				a.href = ObjectUrl;
				a.download = 'report.docx';
				a.click();
				window.URL.revokeObjectURL(ObjectUrl);
				document.body.removeChild(a);
			}
		);
	}

	update = async (subsection, data) => {
		let json = JSON.stringify(data);
		const response = await fetch(getServiceUri() + '/' + subsection, {
			method: 'PUT',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: json
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	delete = async (subsection, id) => {
		const response = await fetch(getServiceUri() + '/' + subsection + '/' + id, {
			method: 'DELETE',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer' // no-referrer, *client
		});
		if (response.status===401)
			this.oidcService.login();
		return response;
	}

	purge = async (subsection, id) => {
		const response = await fetch(getServiceUri() + '/' + subsection + '/purge/' + id, {
			method: 'DELETE',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer' // no-referrer, *client
		});
		if (response.status===401)
			this.oidcService.login();
		return response;
	}

	search = async (subsection, filter) => {
		let json = JSON.stringify(filter);
		const response = await fetch(getServiceUri() + '/' + subsection + '/search/', {
			method: 'POST',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: json
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	list = async (subsection, filter) => {
		let json = JSON.stringify(filter);
		const response = await fetch(getServiceUri() + '/' + subsection + '/list/', {
			method: 'POST',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: json
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	undelete = async (subsection, data) => {
		let json = JSON.stringify(data);
		const response = await fetch(getServiceUri() + '/' + subsection + '/', {
			method: 'POST',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: json
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	getRelation = async (subsection, target, id) => {
		const response = await fetch(getServiceUri() + '/' + subsection + '/' + target + '/list' + '/' + id, {
			method: 'GET',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer' // no-referrer, *client
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	getService = async (serviceId) => {
		const response = await fetch('/eureka/apps/' + serviceId, {
			method: 'GET',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer' // no-referrer, *client
		});
		return await response.json();
	}

	bulkOperation = async (relation, data) => {
		let json = JSON.stringify(data);
		console.log(json);
		const response = await fetch(getServiceUri() + '/' + relation + '/bulk/', {
			method: 'PUT',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: json
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	upload = async (subsection, id, file) => {
		let formData = new FormData();
		formData.append("file", file);
		const response = await fetch(getServiceUri() + '/' + subsection + '/' + id, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			body: formData
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	join = async (subsection, target, filter) => {
		let json = JSON.stringify(filter);
		const response = await fetch(getServiceUri() + '/' + subsection + '/' + target + '/join/', {
			method: 'POST',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: json
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}
	
		setPreferences = async (filter) => {
		let json = JSON.stringify(filter);
		const response = await fetch(getServiceUri() + '/' + 'preferences' + '/' + 'save', {
			method: 'POST',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: json
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}

	readPreferences = async () => {
		const response = await fetch(getServiceUri() + '/' + 'preferences' + '/' + 'read', {
			method: 'Get',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
		});
		if (response.status===401)
			this.oidcService.login();
		return await response.json();
	}
	
	loadImage = async (url) => {
		console.log(url);
		const response = await fetch(url, {
			method: 'Get',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
		})
		return await response.blob();
	}

	getToken = async (url) => {
		try {
			const response = await fetch(url, {
					method: 'GET',
					mode: 'cors',
					cache: 'no-cache',
					credentials: 'same-origin',
					headers: {
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + this.oidcService.token
					},
					redirect: 'follow',
					referrerPolicy: 'no-referrer',
			});

				return await response.text();
		} catch (error) {
				console.error('Error:', error);
		}
	}
	
	downloadAndSaveFile = async (url) => {
		try {
	
		// Fetch the file content from the URL in chunks
		const response = await fetch(url, {
			method: 'Get',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.oidcService.token
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
		});
		const reader = response.body.getReader();
		const contentDispositionHeader = response.headers.get('content-disposition');
		let fileName = 'default_filename'; // Default filename if not found in headers

		if (contentDispositionHeader) {
			const filenameMatch = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
			if (filenameMatch && filenameMatch[1]) {
				fileName = filenameMatch[1].replace(/['"]/g, '');
			}
		}

		// Request access to the Downloads directory
		const opts = {
			suggestedName: fileName,
		  };
		const dirHandle = await window.showSaveFilePicker(opts);
	
		// Create a file and write content to it
		const writable = await dirHandle.createWritable();
	
		while (true) {
			const { done, value } = await reader.read();
			if (done) {
			console.log('File downloaded and saved successfully.');
			break;
			}
	
			// Write the chunk to the file
			await writable.write(value);
		}
	
		// Close the writable stream
		await writable.close();
		} catch (error) {
		console.error('Error:', error);
		}
	}
}

export const rest = new RestClient();
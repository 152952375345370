import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { AlertDialog } from "../../widgets";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { saveDeathRegistrationForm, updateDeathRegistrationForm, deleteDeathRegistrationForm, loadDeathRegistrationFormData } from "./DeathRegistrationApi";
import { buildDeathRegistrationFormEmptyObject, deathRegistrationFields } from "../../../../main/js/forms/deathRegistration/DeathRegistrationFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../../widgets/FileUploader";
import { createTableComponent } from "../../widgets/TableComponent";


export class DeathRegistrationFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = (id) => {
		deleteDeathRegistrationForm(id);
		this.state.closeRequested(true);
	}

	render() {
	let NewDeathRegistrationForm = createFormComponent(this.props.fields);

		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<NewDeathRegistrationForm ref={this.myRef} key={this.props.key} id={this.props.id} loadData={async () => loadDeathRegistrationFormData(this.props.id)} onSubmit={this.props.onSubmit} buttons={getButtons} />
		</>
		)
	}

}


export const displayNewDeathRegistrationForm = (formData) => {
	let data;
	if (!formData)
		data = buildDeathRegistrationFormEmptyObject();
	else
		data = formData;
	data.draft = true;
	saveDeathRegistrationForm(data).then((response) => {
		let uuid = uuidv4();
		OPEN_VIEW_EVENT.publish({
		uuid, view: () => <DeathRegistrationFormComponent key={uuid} id={response} onSubmit={save} fields={deathRegistrationFields}/>
		});
	});
}

const save = async (formData) => {
	formData.draft = false;
	return updateDeathRegistrationForm(formData).then((response) =>{
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Created New DeathRegistrationForm`, "success");
	});
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}

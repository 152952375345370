import React from "react";
import _ from 'lodash';
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import { dto2form, form2dto } from "../../../../main/js/forms/deathRegistration/DeathRegistrationFormCommon";

export const saveDeathRegistrationForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
    form2dto(formData, dto);
    try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/new-document', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadDeathRegistrationFormData = async (id) => {
	return await rest.read('civil-status-mtlb', id).then(response => {
		let form = dto2form(response);
		let tagFilter = {and: true};
		tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('civil-status-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingDeathRegistrationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated death-registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateDeathRegistrationForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.update('civil-status-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created death-registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteDeathRegistrationForm = async (id) => {
    try {
        return rest.delete('civil-status-mtlb', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Deleted death-registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedDeathRegistrationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated death-registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectDeathRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyDeathRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyDeathRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

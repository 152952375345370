import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { t } from "../services";
import { TextField } from '@mui/material';

export function AlertDialog(props) {
  const [open, setOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(props.confirmMessage);
  useEffect(()  => {
        setOpen(props.open)
        setConfirmMessage(props.confirmMessage)
    },[props])


  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(props.noCancel)? null :
            <Button onClick={props.handleClose} color="primary">
            {t`cancel`}
            </Button>
          }
          {(props.noAgree)? null :
            <Button onClick={props.agree} color="primary" autoFocus>
            {t`ok`}
            </Button>
          }
          {(props.save)?
            <Button onClick={props.save} color="primary" autoFocus>
            {t`save`}
            </Button>
            : null
          }
          {(props.dontSave)?
            <Button onClick={props.dontSave} color="primary" autoFocus>
            {t`dont save`}
            </Button>
            : null
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  useEffect(()  => {
      setOpen(props.open)
  },[props])

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={props.handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.message}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            multiline
		        rowsMax="4"
            label={props.label}
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>{t`Cancel`}</Button>
          <Button onClick={() => props.handleSave(value)}>{t`Save`}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { MTLB_TYPE_DIVORCE } from "../../../../auto/js/metadata/MtlbType";
import { UNION_MTLB_ORDER_BY_PARTNER1_FIRSTNAME } from "../../../../auto/js/metadata/UnionMtlbOrderBy";
import { rest } from "../../../../auto/js/services";


export const divorceRegistrationListColumns = [
    {title: "partner1VitalRecordId", field: "partner1VitalRecordId"},
    {title: "partner1Firstname", field: "partner1Firstname"},
    {title: "partner1Surname", field: "partner1Surname"},
    {title: "partner2VitalRecordId", field: "partner2VitalRecordId"},
    {title: "partner2Firstname", field: "partner2Firstname"},
    {title: "partner2Surname", field: "partner2Surname"},
    {title: "place", field: "place"},
];

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "union-mtlb/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "union-mtlb/count-document/", "POST", filter);
}

export const getOrderBy = () => {
    return UNION_MTLB_ORDER_BY_PARTNER1_FIRSTNAME
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_DIVORCE]
}

export const filterData = (DefaultRows) => {
    return DefaultRows;
}

export const getErrorList = () => {
    let list = [];
	list.push("E32");
    return list;
}
import { rest } from "../../../auto/js/services/RestClient";
import { OFFICE_ORDER_BY_TYPE } from "../../../auto/js/metadata/OfficeOrderBy";

class OfficeMetadataLoader {

    constructor() {
        if (!!OfficeMetadataLoader.instance) {
            return OfficeMetadataLoader.instance;
    }
    OfficeMetadataLoader.instance = this;
    this.areas= {};
    this.children = {};
    this.locationsdata = [];
    this.maxAreaId = 0;
    return this;
}

	load = async () => {
		let filter = {};
		filter['office'] = {disabled: false};
		filter.orderBy = OFFICE_ORDER_BY_TYPE;
        this.areas= {};
		this.rootAreaIdList = [];
		return rest.search('office', filter)
			.then(response => {
				response.forEach(element => {
					this.areas[element.areaId] = { "name": element.name, "id": element.id };
					if (element.type < 7 ) {
						this.children[element.areaId] = [];
					}
					if (element.type == 1) {
						this.rootAreaIdList.push(element.areaId);
						if (parseInt(element.areaId) > this.maxAreaId)
							this.maxAreaId = parseInt(element.areaId)
					}
					if (!this.isRootNode(element.areaId)) {
						let parentareaid = this.getParentAreaId(element.areaId);
						this.children[parentareaid]? this.children[parentareaid].push(element.areaId) :
						this.children[parentareaid] = [element.areaId];
					}
				});
			})
			.then(()=> {
			this.locationsdata = [];
			this.preparelocationsdata();
			});
	}

	preparelocationsdata = () => {
		var eldetails = [];
		const getLocationsFlat = (ellist, eldetail) => {
			ellist.forEach(el => {
			var elname = eldetail + "#" + this.areas[el].name + "::" + this.areas[el].id;
			if (Array.isArray(this.children[el]) && (this.children[el].length)) {
				getLocationsFlat(this.children[el], elname);
			}
			else {
				eldetails.push({"Hierarchy":elname, "Areaid": el});
			}
			});
			return eldetails;
		}
		this.rootAreaIdList.forEach(element => {
			if (this.children[element].length)
				eldetails = getLocationsFlat(this.children[element],
					this.areas[element].name + "::" + this.areas[element].id)
		});
		
		eldetails.forEach(el => {
			const ArrayData = el.Hierarchy.split("#");
			this.locationsdata.push({
			    "conservatoire": ArrayData[0]?.split("::")[0],
			    "conservatoireId": ArrayData[0]?.split("::")[1],
			    "office": ArrayData[1]?.split("::")[0],
			    "officeId": ArrayData[1]?.split("::")[1],
			    "areaId": el.Areaid
			})
		});
	}

	getParentAreaId = (areaId) => {
		return areaId.substring(0, areaId.lastIndexOf("."))
	}

	isRootNode = (areaId) => {
		return areaId.lastIndexOf(".") === -1;
	}

	getRootNodes = () => {
  		let areaids = Object.keys(this.areas).filter(key => key.lastIndexOf(".") === -1);
	    let result = Object.fromEntries(areaids.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result;
	}

	getChilds = (id) => {
		let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let result = Object.fromEntries(this.children[areaid].map(x => [this.areas[x].id, this.areas[x].name]));
		return result;
	}
	
    getAreaId = (id) => {
        return Object.keys(this.areas).find(key => this.areas[key].id == id);
    }
    
    getChildsByAreaId = (areaId) => {
		if (this.children[areaId])
			return Object.fromEntries(this.children[areaId].map(x => [this.areas[x].id, this.areas[x].name]));
		else
			return []
	}
    
    getArea = (areaId) => {
        return this.areas[areaId];
    }
    
    getAreaLevelName = (areaId) => {
		let areaComponents = areaId.split(".");
		switch (areaComponents.length) {
			case (1):
				return "conservatoire";
			case (2):
				return "office";
		}
	}
    
    getGeoLevel = (id, level) => {
		let areaId = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let areaIds = Object.keys(this.areas).filter(key => key.split(".").length === level + 1 && key.startsWith(areaId))
		let result = Object.fromEntries(areaIds.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result; 
	}
	
	getNewAreaId = () => {
		this.maxAreaId = this.maxAreaId + 1;
		return this.maxAreaId;
	}

	incrementOfficeAreaIdFromParentId = (id) => {
		let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let biggerAreaId = Math.max(...this.children[areaid].map(x => x.split(".").slice(-1)[0]));
		let nextAreaId = `${areaid}.${biggerAreaId + 1}`;
		return nextAreaId;	
	}

}

export const officeMetadataLoader = new OfficeMetadataLoader();

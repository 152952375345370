import * as React from 'react';
import { formatDateTime } from "../utils";

export class NotesGrid extends React.Component {
	constructor(props) {
		super(props);
		this.list = props.list;
	}

	note = (data) => {
		let timestamp = data.timestamp? formatDateTime(data.timestamp):'Unknown';
		
		let author = data.addedBy;
		let content = data.content;
		return (
			<tr><td><b>{timestamp},</b> </td> <td><b> {author}:</b></td> <td> {content}</td> </tr>
		)
	}

	render() {
		let rows = [];
		this.list.forEach(element => {
			rows.push(this.note(element));
		});
		
		return (
			<table>
				<tbody>
					{rows.reverse()}
				</tbody>
			</table>
		)
	}
}
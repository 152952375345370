import React from "react";

import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_DIVORCE } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";

export const divorceRegistrationFields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "partner1VitalRecordId", type: "text", x:1, y:2, layout:"col-md-12"},
    {name: "partner1Firstname", type: "text", x:1, y:3, layout:"col-md-6"},
    {name: "partner1Surname", type: "text", x:2, y:3, layout:"col-md-6"},
    {name: "partner2VitalRecordId", type: "text", x:1, y:4, layout:"col-md-12"},
    {name: "partner2Firstname", type: "text", x:1, y: 5, layout:"col-md-6"},
    {name: "partner2Surname", type: "text", x:2, y: 5, layout:"col-md-6"},
    {name: "place", type: "text", x:1, y: 6, layout:"col-md-6"},
    {name: "declaredDate", type: "date", x:1, y: 6, layout:"col-md-6"},
    {name: "notes", type: "text", x:1, y:7, layout:"col-md-12"}
];

export const saveDivorceRegistrationForm = async (formData) => {
    let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    dto.mtlbType = MTLB_TYPE_DIVORCE;
    try {
        return rest.request(getServiceUri() + 'union-mtlb', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}


export const form2dto = (formData, dto) => {
	dto.mtlbType = MTLB_TYPE_DIVORCE;
	if(formData.declaredDate != null && typeof(formData.declaredDate) != 'string') {
		let declaredDateAsDate = new Date(formData.declaredDate);
		dto.declaredDate = declaredDateAsDate.getFullYear() + "-" + ('0' + (declaredDateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + declaredDateAsDate.getDate()).slice(-2);
	}
}

export const dto2form = (response) => {
	return response;
    
}

export const loadDivorceRegistrationFormData = async (id) => {
	return await rest.read('union-mtlb', id).then(response => {
		let form = response;
		let tagFilter = {and: true};
		tagFilter['union-mtlb-tag'] = {unionMtlbId: id};
        if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error")
        else
            showNotification(t`Read Divorce Registration`, "success");
		return rest.search('union-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
            if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingDivorceRegistrationForm = async (formData) => {
	let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    dto.mtlbStatus = "SUBMITTED";
    try {
        return rest.request(getServiceUri() + 'union-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated Divorce Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedDivorceRegistrationForm = async (formData) => {
	let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    try {
        return rest.request(getServiceUri() + 'union-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Reopened Divorce Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectDivorceRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'union-mtlb/pending/reject', 'POST', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Rejected Divorce Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const buildDivorceRegistrationFormEmptyObject  = () => {
    const empty = buildEmptyObject(divorceRegistrationFields);
	return empty;
}

export const updateDivorceRegistrationForm = async (formData) => {
	let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    dto.mtlbStatus = "SUBMITTED";
    dto.draft = false;
    try {
        return rest.update('union-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created Divorce Registration`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteDivorceRegistrationForm = async (id) => {
    try {
        return rest.delete('union-mtlb', id);
    } catch (err) {
        alert(err);
    }
}
export const MtlbStatus = {
	"SUBMITTED": 1
	,
	"MANUAL_CHECK": 2
	,
	"ARCHIVED": 3
	,
	"AFIS_RECORD_CREATED": 4
	,
	"AFIS_11_CHECKED": 5
	,
	"AFIS_1N_SUBMITTED": 6
	,
	"AFIS_1N_CHECKED": 7
	,
	"AFIS_11_MISMATCH": 8
	,
	"AFIS_1N_MATCH_FOUND": 9
	,
	"REJECTED": 10
	,
	"APPROVED": 11
	,
	"INITIATED": 12
	,
	"PRINT_REQUEST": 13
	,
	"PRINTED": 14
	,
	"ASSIGNED": 15
	,
	"READY_FOR_APPROVAL": 16
	,
	"NOT_ASSIGNED": 17
	
}
Object.freeze(MtlbStatus);
const toTypeMap = {};
Object.keys(MtlbStatus).forEach(key => {
	toTypeMap[MtlbStatus[key]] = key;
});
export function getMtlbStatus (value) {
	return toTypeMap[value];
}

export function getAllMtlbStatus () {
    return Object.keys(MtlbStatus);
}

export const MTLB_STATUS_SUBMITTED = "SUBMITTED";
export const MTLB_STATUS_MANUAL_CHECK = "MANUAL_CHECK";
export const MTLB_STATUS_ARCHIVED = "ARCHIVED";
export const MTLB_STATUS_AFIS_RECORD_CREATED = "AFIS_RECORD_CREATED";
export const MTLB_STATUS_AFIS_11_CHECKED = "AFIS_11_CHECKED";
export const MTLB_STATUS_AFIS_1N_SUBMITTED = "AFIS_1N_SUBMITTED";
export const MTLB_STATUS_AFIS_1N_CHECKED = "AFIS_1N_CHECKED";
export const MTLB_STATUS_AFIS_11_MISMATCH = "AFIS_11_MISMATCH";
export const MTLB_STATUS_AFIS_1N_MATCH_FOUND = "AFIS_1N_MATCH_FOUND";
export const MTLB_STATUS_REJECTED = "REJECTED";
export const MTLB_STATUS_APPROVED = "APPROVED";
export const MTLB_STATUS_INITIATED = "INITIATED";
export const MTLB_STATUS_PRINT_REQUEST = "PRINT_REQUEST";
export const MTLB_STATUS_PRINTED = "PRINTED";
export const MTLB_STATUS_ASSIGNED = "ASSIGNED";
export const MTLB_STATUS_READY_FOR_APPROVAL = "READY_FOR_APPROVAL";
export const MTLB_STATUS_NOT_ASSIGNED = "NOT_ASSIGNED";

class WindowContext {
    constructor() {
        if (!!WindowContext.instance) {
            return WindowContext.instance;
        }

        WindowContext.instance = this;
        this.openedWindows = undefined;
        return this;
    }

    getOpenedWindows() {
        return this.openedWindows;
    }

    setOpenedWindows(w) {
        this.openedWindows = w;
    }
}

export const windowContext = new WindowContext();

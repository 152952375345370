import { metadataLoader } from "../../auto/js/metadata"
import { geoMetadataLoader } from "../../auto/js/metadata/GeoMetadataLoader";
import { officeMetadataLoader } from "../../main/js/metadata/OfficeMetadataLoader";

export const loadCountries = async () => {
    return metadataLoader.load("country")
}

export const loadLocations = async () => {
    return geoMetadataLoader.load();
}

export const loadOffices = async () => {
    return officeMetadataLoader.load();
}
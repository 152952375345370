import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import {createTableComponent, deleteItem} from "../../../../auto/js/widgets/TableComponent";
import {officeMetadataLoader} from "../../metadata/OfficeMetadataLoader";

import {pojoMetadata} from "../../../../auto/js/metadata";
import {showNotification} from "../../../../auto/js/utils";
import { displayReadOfficeForm } from "../../forms/metadata/OfficeForm";

const fields = [
    {title: "conservatoire", field: "conservatoire"},
    {title: "office", field: "office"}
];



export const displayOfficeList = () => {
    let OfficeList = createTableComponent(fields);
    let uuid = uuidv4();
    OPEN_VIEW_EVENT.publish({
        uuid, view: () => <OfficeList key={uuid} localData={true} loadData={officeMetadataLoader.locationsdata} editable={getTableEditable()} actions={getTableActions()} />
    });
}

const getTableEditable = () => {
    let editables = {};
    editables.onRowDelete = rowData =>
    new Promise((resolve, reject) => {
        try {
            let dto = form2dto(rowData);
            return rest.update('office', dto).then((response) =>
            {
                if (response.status) {
                    showNotification(response.message?.split('Detail: ')[1], "error");
                    reject();
                }
                else {
                    showNotification(t`Deleted Office`, "success");
                    officeMetadataLoader.load().then(() => resolve());
                }
            })
        } catch (err) {
            alert(err);
            reject();
        }
    }).then(() => displayOfficeList());
    return editables;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    displayReadOfficeForm({
                        "office": rowData.office,
                        "conservatoire": {key: rowData.conservatoireId, value: rowData.conservatoire},
                        "id": rowData.officeId,
                        "areaId": rowData.areaId
                    });
                }
        }
    );
    return actions;   
}


const form2dto = (data) => {
    let updatedata = {};
    updatedata.id = ~~(data.officeId);
    updatedata.areaId = data.areaId;
    updatedata.name = data.office;
    updatedata.type = 2;
    updatedata.parentId = ~~(data.conservatoireId);
    if(updatedata.areaId == "")
    updatedata.areaId = null;
    if(updatedata.name == "")
    updatedata.name = null;
    updatedata.disabled = true;
    return updatedata
}